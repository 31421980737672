import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react";
import ArrowButton from "../../../../components/animatedArrow/animatedArrowButton";
import ServicesCards from "./servicesCards";
import "../../../../components/utilityBold.css";
import { useNavigate } from "react-router-dom";

const WhoServices = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Stack
        direction={[
          "column !important",
          "column !important",
          "column !important",
          "row !important",
        ]}
        data-aos="fade-up"
        alignItems="center"
        justifyContent="space-between"
        gap={["4rem", "4rem", "4rem", "13rem"]}
        padding={["2rem 2rem 0 2rem", "6rem 6rem 0 6rem"]}
      >
        <Box>
          <Heading
            marginLeft="150px"
            lineHeight="40px"
            size="lg"
            className="bold"
            fontSize={["4em", "7rem"]}
            color="#290F44"
            opacity="0.1"
          >
            WHO
          </Heading>
          {/* <Flex> */}
          <Heading
            maxW="800px"
            fontWeight="800"
            className="bold"
            fontSize="1.9rem"
            color="#290F44"
            textAlign={["center", "initial"]}
          >
            WE ARE NOT JUST AN ECOSYSTEM OF CREATIVITY{" "}
            <Box
              display={"inline-block"}
              w="10px"
              h="10px"
              borderRadius="50%"
              bg="#00FE98"
            />
          </Heading>
        </Box>
        <Box>
          <Text color="#434144" mb={7}>
            For each business, we take a bespoke approach to developing change
            within the organization, often with the common goal.
          </Text>
          <Text mb={6} color="#434144">
            This is the main factor that sets us apart from our competitors and
            allows us to deliver a usable product for the users and also
            profitable for the business . Our team applies its wide-ranging
            experience to determining the strategies that will best.
          </Text>
          <ArrowButton
            text="About Us"
            color="#05C97A"
            onClick={() => navigate("/about")}
          />
        </Box>
      </Stack>
      <Stack
        direction={[
          "column !important",
          "column !important",
          "column !important",
          "row !important",
        ]}
        data-aos="fade-up"
        marginTop="7rem"
        marginBottom="2rem"
        alignItems="center"
        justifyContent="space-between"
        gap="2rem"
        padding="6rem 6rem 0 6rem"
      >
        <Box>
          <Heading
            marginLeft="60px"
            lineHeight="40px"
            size="lg"
            className="bold"
            fontSize={["4em", "7rem"]}
            color="#290F44"
            opacity="0.1"
          >
            SERVICES
          </Heading>
          {/* <Flex> */}
          <Heading
            className="bold"
            maxW="500px"
            fontWeight="600"
            fontSize="1.9rem"
            color="#290F44"
            textAlign={["center", "initial"]}
          >
            LEARN MORE ABOUT OUR SERVICES
            <Box
              display={"inline-block"}
              ml="4px"
              w="10px"
              h="10px"
              borderRadius="50%"
              bg="#00FE98"
            />
          </Heading>
          {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
          {/* </Flex> */}
        </Box>
        <Button
          mt={10}
          width={170}
          padding="1.5rem 3rem"
          borderRadius="5px"
          bg="#290F44"
          _hover={{ background: "#290F44" }}
          color="#fff"
          fontSize="14px"
          onClick={() => navigate("/services")}
          boxShadow={"13px 20px 20px 0px #290f443b;"}
        >
          VIEW ALL SERVICES
        </Button>
      </Stack>
      <ServicesCards />
    </Box>
  );
};

export default WhoServices;
