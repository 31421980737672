import { Box, Button, Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Man from "./man.png";

const Vision = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Stack
        mt="5rem"
        bg="#F6F8FF"
        direction={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row-reverse",
        ]}
      >
        <Box flex="1" data-aos="fade-left">
          <Box
            padding={[
              "8rem 2rem 3rem 2rem",
              "8rem 2rem 3rem 2rem",
              "8rem 2rem 3rem 2rem",
              "8rem 6rem 3rem 6rem",
            ]}
          >
            <Text mb={6} fontSize="1.5rem">
              OUR VISION
            </Text>
            <Text mb={4} className="bold" fontSize="2.3rem">
              Our goal is to become the standards for product development in
              Africa and beyond
            </Text>
            <Text lineHeight="45px" fontSize="1.4rem" mt={8} mb={4}>
              At Zealight we put all our resources – human and material
              resources with our experience into building products and people
              who do exceptional work.
            </Text>
            <Button
              mb={6}
              width={170}
              padding="1.5rem 3rem"
              borderRadius="5px"
              bg="#290F44"
              _hover={{ background: "#00FE98" }}
              color="#fff"
              fontSize="14px"
              onClick={() => navigate("/services")}
            >
              VIEW ALL SERVICES
            </Button>
          </Box>
        </Box>
        <Box
          data-aos="fade-right"
          backgroundImage={Man}
          backgroundSize="cover"
          backgroundPosition="center"
          flex="1"
        ></Box>
      </Stack>
    </Box>
  );
};

export default Vision;
