import { Box, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { AiOutlineCheck } from "react-icons/ai";

function ProductProcessList({ lists }) {
  return (
    <Stack
      direction={"row"}
      justifyContent="center"
      alignItems={"center"}
      //   my="150px"
      mb="90px"
    >
      <SimpleGrid columns={[1, 1, 2, 3]} spacing="60px">
        {lists.map((data) => {
          return (
            <Box>
              <Text fontFamily={"Trenda-bold"} fontSize="1.5em">
                {data?.title}
              </Text>
              <Text>{data?.subTitle}</Text>
              <Stack spacing="30px" mt="30px">
                {data?.processes?.map((data) => {
                  return (
                    <Stack
                      direction={"row"}
                      spacing="30px"
                      alignItems={"center"}
                    >
                      <AiOutlineCheck />
                      <Text fontWeight={"bold"}>{data}</Text>
                    </Stack>
                  );
                })}
              </Stack>
            </Box>
          );
        })}
      </SimpleGrid>
    </Stack>
  );
}

export default ProductProcessList;
