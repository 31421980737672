import { Box, Img, Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import ContactBanner from "../../../landing/components/contactBanner/contactbanner";
import ListBoxes from "../ListBoxes";
import ServiceDetailHeader from "../ServiceDetailHeader";
import mobileDevAndriod from "../../../../assets/mobile-dev-andriod.svg";
import mobileDevIos from "../../../../assets/mobile-dev-ios.svg";
import mobileDev1 from "../../../../assets/mobile-dev-1.png";
import TextOverlayOnImage from "../TextOverlayOnImage";

function MobileAppDevelopment() {
  const lists = [
    {
      title: "Development",
      description:
        "Organize your ideas and create a mobile application from scratch.",
    },
    {
      title: "Analyze & Enhance",
      description: "An existing mobile app can be improved.",
    },

    {
      title: "Support & Maintain",
      description:
        "Make sure the app is constantly updated and functions correctly.",
    },
    {
      title: "Follow Up",
      description:
        "Ensure infrastructure, integration, and API communication are all in order.",
    },
    {
      title: "Project Processes",
      description:
        "from conception to creation to publication on mobile app stores.",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box data-aos="fade-up" bg="#fefffe">
      <ServiceDetailHeader
        subHeading={"Mobile App Development"}
        heading={
          "WE WORK WITH BUSINESSES ALL OVER THE WORLD TO CREATE UNIQUE MOBILE APPLICATIONS THAT GIVE THEM A COMPETITIVE EDGEN"
        }
      />
      <TextOverlayOnImage
        img={mobileDev1}
        title={"CREATING SUCCESSFUL APPROACH TO MOBILE FIRST PROBLEMS"}
        desc="We have a wealth of experience in mobile app development, having worked on hundreds of projects, which means we have the expertise to produce apps that will drive real business results. We are a mobile-first agency."
        containerColor="#f7f7f7ed"
      />

      <Stack
        direction="row"
        justifyContent={"center"}
        alignItems="center"
        spacing={"150px"}
      >
        <Stack maxW="200px" justifyContent={"center"} alignItems="center">
          <Img loading="lazy" w="80px" src={mobileDevAndriod} alt="andriod" />
          <Text fontFamily={"Trenda-heavy"} color="#100120">
            ANDRIOD
          </Text>
          <Text textAlign={"center"}>
            Create user-friendly mobile experiences for Android by utilizing all
            of the platform's features.
          </Text>
        </Stack>
        <Stack maxW="200px" justifyContent={"center"} alignItems="center">
          <Img loading="lazy" w="100px" src={mobileDevIos} alt="ios" />
          <Text fontFamily={"Trenda-heavy"} color="#100120">
            IOS
          </Text>
          <Text textAlign={"center"}>
            Create user-centric mobile applications for iOS using top notch
            technology standards.
          </Text>
        </Stack>
      </Stack>
      <ListBoxes lists={lists} />
      <ContactBanner />
    </Box>
  );
}

export default MobileAppDevelopment;
