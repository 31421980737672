import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import ContactBanner from "../landing/components/contactBanner/contactbanner";
import Hero from "./components/hero/hero";
import Productsx from "./components/products/products";

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <Hero />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Productsx />
      <br />
      <br />
      <br />
      <br />
      <br />
      <ContactBanner />
    </Box>
  );
};

export default Portfolio;
