import { Box, Button, Heading, Img, Stack, Text } from "@chakra-ui/react";
import { CgArrowLongRight } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

function ProductDetailsTemplate({ product = null }) {
  const navigate = useNavigate();
  return (
    <Box>
      {product ? (
        product?.data?.map((prod, index) => {
          return (
            <Stack
              key={index}
              direction={[
                prod?.imageDetails?.moveImgRight ? "column" : "column-reverse",
                prod?.imageDetails?.moveImgRight ? "row" : "row-reverse",
              ]}
              mb={["150px", "350px"]}
              mt={["0px", "80px"]}
              position={"relative"}
              justifyContent="space-between"
              alignItems={["flex-start", "center"]}
              bg={prod?.backgroundColor}
              px={prod?.backgroundColor ? "90px" : "initial"}
              py={prod?.backgroundColor ? "90px" : "initial"}
              mx={prod?.backgroundColor ? "-6rem" : "initial"}
              //   h={prod?.backgroundColor ? "500px" : "initial"}
            >
              <Stack maxW="600px" spacing={"25px"}>
                {index === 0 && (
                  <Text fontWeight={"bold"} color="#290F44" fontSize={"1.5em"}>
                    {product?.brandName}
                  </Text>
                )}
                <Heading fontFamily={"Trenda-bold"} fontSize="3.5em">
                  {prod?.title}
                </Heading>
                <Text color="#434144" maxW="400px">
                  {prod?.description}
                </Text>
                {index === 0 && (
                  <Button
                    color="#fff"
                    bg="#290F44"
                    _hover={{ background: "#290F44" }}
                    size="sm"
                    w="130px"
                    onClick={() => navigate("/contact")}
                  >
                    Book a Demo
                  </Button>
                )}
                {index !== 0 && (
                  <Stack direction={"row"} alignItems="center" color="#05C97A">
                    <Text
                      alignSelf={"baseline"}
                      fontSize="1em"
                      cursor={"pointer"}
                    >
                      Book a Demo
                    </Text>
                    <CgArrowLongRight />
                  </Stack>
                )}
              </Stack>
              <Box w={["300px", "400px"]} position={"relative"} zIndex="555">
                <Text
                  fontSize={["4em", "5.5em"]}
                  left={prod?.imageDetails?.moveImgRight ? "-100px" : "80px"}
                  top={["-70px", "-160px"]}
                  fontFamily={"Trenda-heavy"}
                  position={"absolute"}
                  color="#ebe8ee"
                  whiteSpace={"nowrap"}
                  zIndex="222"
                >
                  {prod?.imageDetails?.waterMark}
                </Text>
                <Box
                  borderRadius={"20px"}
                  overflow="hidden"
                  position={"relative"}
                  zIndex="333"
                  transform={[
                    "scale(1)",
                    index === 0 ? "scale(1.4)" : "scale(1.3)",
                  ]}
                >
                  <Img
                    loading="lazy"
                    transition="all .2s ease-in-out"
                    _hover={{ transform: "scale(1.1)" }}
                    src={prod?.imageDetails?.image}
                    h={["300px", "100%"]}
                    // transform={"scale(1.2)"}
                    // w="600px"
                    alt={prod?.title}
                    objectFit={"cover"}
                    alignSelf="baseline"
                  />
                </Box>
              </Box>
            </Stack>
          );
        })
      ) : (
        <Text textAlign={"center"}>Incorrect url</Text>
      )}
    </Box>
  );
}

export default ProductDetailsTemplate;
