import { Box, Flex, Image, SimpleGrid, Stack, Text } from "@chakra-ui/react";

import ArrowButton from "../../../components/animatedArrow/animatedArrowButton";
import { useNavigate } from "react-router-dom";
import { TbCalendarEvent } from "react-icons/tb";
import { HiOutlineUserCircle } from "react-icons/hi";
import dayjs from "dayjs";

const BlogCards = ({ blogs, vertical = false }) => {
  const navigate = useNavigate();
  return (
    <Box marginTop="50px">
      {vertical ? (
        <SimpleGrid columns={[1, 2]} spacing="40px">
          {blogs?.map((blog) => {
            return (
              <Box maxW="500px" flex="1" data-aos="fade-right">
                {/* <Box
                  backgroundImage={blog?.image}
                  backgroundSize="cover"
                  // borderRadius="6px"
                  height="250px"
                  borderRadius="10px"
                ></Box> */}
                <Box borderRadius="20px" overflow={"hidden"}>
                  <Image
                    loading="lazy"
                    transition="all .2s ease-in-out"
                    _hover={{ transform: "scale(1.1)" }}
                    objectFit={"cover"}
                    src={blog?.image}
                    alt="blog pic "
                  />
                </Box>

                <Box padding=".4rem 1rem">
                  <Stack
                    mt="20px"
                    w={["300px", "400px"]}
                    mb={3}
                    alignItems="center"
                    // justifyContent={"space-between"}
                    spacing="30px"
                    direction={"row"}
                  >
                    <Stack direction={"row"} alignItems="center">
                      <TbCalendarEvent color="#434144" />
                      <Text color="#100120" fontSize=".9rem">
                        {dayjs(blog?.createdAt).format("MMMM DD YYYY")}
                      </Text>
                    </Stack>

                    <Stack direction={"row"} alignItems="center">
                      <HiOutlineUserCircle color="#434144" />

                      <Text color="#100120" fontSize=".9rem">
                        {blog?.author}
                      </Text>
                    </Stack>
                  </Stack>
                  <Text
                    mb={3}
                    maxWidth="90%"
                    fontSize="1.4rem"
                    lineHeight="26px"
                    fontWeight="500"
                    color="#100120"
                  >
                    {blog?.title}
                  </Text>
                  <ArrowButton
                    text="Read More"
                    color="#05C97A"
                    onClick={() =>
                      navigate(`/blog/${blog?.title.replace(/ /g, "_")}`)
                    }
                  />
                </Box>
              </Box>
            );
          })}
        </SimpleGrid>
      ) : (
        <Flex
          gap="2.5rem"
          alignItems="center"
          justifyContent="space-between"
          overflowX="scroll"
        >
          {blogs?.map((blog) => {
            return (
              <Box maxW="400px" flex="1" data-aos="fade-right">
                <Box borderRadius="20px" overflow={"hidden"}>
                  <Image
                    loading="lazy"
                    transition="all .2s ease-in-out"
                    _hover={{ transform: "scale(1.1)" }}
                    objectFit={"cover"}
                    src={blog?.image}
                    alt="blog pic "
                  />
                </Box>

                <Box padding=".4rem 1rem">
                  <Stack
                    w={["300px", "350px"]}
                    mb={3}
                    alignItems="center"
                    justifyContent={"space-between"}
                    spacing="30px"
                    direction={"row"}
                  >
                    <Stack direction={"row"} alignItems="center">
                      <TbCalendarEvent color="#434144" />
                      <Text color="#100120" fontSize=".9rem">
                        {dayjs(blog?.createdAt).format("MMMM DD YYYY")}
                      </Text>
                    </Stack>

                    <Stack direction={"row"} alignItems="center">
                      <HiOutlineUserCircle color="#434144" />

                      <Text color="#100120" fontSize=".9rem">
                        {blog?.author}
                      </Text>
                    </Stack>
                  </Stack>
                  <Text
                    mb={3}
                    maxWidth="90%"
                    fontSize="1.4rem"
                    lineHeight="26px"
                    fontWeight="500"
                    color="#100120"
                  >
                    {blog?.title}
                  </Text>
                  <ArrowButton
                    text="Read More"
                    color="#05C97A"
                    onClick={() =>
                      navigate(`/blog/${blog?.title.replace(/ /g, "_")}`)
                    }
                  />
                </Box>
              </Box>
            );
          })}
        </Flex>
      )}
    </Box>
  );
};

export default BlogCards;
