import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import ContactBanner from "../landing/components/contactBanner/contactbanner";
import CollabBanner from "./components/collabBanner/collabBanner";
import Hero from "./components/hero/hero";
import Info from "./components/info/info";
import ServicesCards from "./components/servicesCards/servicesCards";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <Hero />
      <Info />
      <ServicesCards />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <CollabBanner />
      <br />
      <br />
      <br />
      <br />
      <br />
      <ContactBanner />
    </Box>
  );
};

export default Services;
