import { Img, Stack, Text } from "@chakra-ui/react";

function TextOverlayOnImage({ img, title, desc, containerColor }) {
  return (
    <Stack
      display="grid"
      gridTemplateColumns="50%"
      position={"relative"}
      mb="90px"
    >
      <Stack width="100vw">
        <Img loading="lazy" w="100%" src={img} alt="imgage" zIndex="444" />
      </Stack>

      <Stack
        w="100%"
        right="0"
        justifyContent={"space-between"}
        alignItems="center"
        direction={["column", "row"]}
        position={"absolute"}
        zIndex="555"
        background={containerColor}
        bottom=" 0"
        // marginBottom={["40px !important", "70px !important"]}
        padding={["10px", "80px 50px"]}
        // pb={["0px", "10px"]}
      >
        <Text
          fontFamily={"Trenda-bold"}
          fontSize={["1em", "1.5em"]}
          maxW="300px"
        >
          {title}
        </Text>
        <Text maxW="500px" fontSize={[".8em", "1em"]}>
          {desc}
        </Text>
      </Stack>
    </Stack>
  );
}

export default TextOverlayOnImage;
