import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { ball } from "../pages/landing/components/ballMovements";

function IncludeBallAnimation() {
  useEffect(() => {
    // Balls

    let redball = new ball("redball");
    let greenball = new ball("greenball");
    // let blueball = new ball('blueball');
    let balls = [redball, greenball];

    // Random speed
    function randomSpeed(i) {
      balls[i].speed.x = 300 * (Math.random() - 0.5);
      balls[i].speed.y = 300 * (Math.random() - 0.5);
    }

    // Initial speed
    for (let i = 0; i < balls.length; i++) {
      randomSpeed(i);
    }

    // Play
    const playInterval = window.setInterval(function () {
      randomSpeed(Math.floor(2 * Math.random()));
    }, 2000);

    // Paint
    const paintInterval = window.setInterval(function () {
      redball.paint();
      greenball.paint();
      // blueball.paint();
    }, 10);

    return () => {
      clearInterval(playInterval);
      clearInterval(paintInterval);
    };
  }, []);
  return (
    <Box id="container">
      <Box id="redball"></Box>
      <Box id="greenball"></Box>
    </Box>
  );
}

export default IncludeBallAnimation;
