import React, { useEffect } from "react";
import { SingleProductPage } from "../howbodi/components/SingleProductPage";
import okrBanner from "./assets/OKR-banner.png";
import okr_about from "./assets/okr-about.png";
import okr_landing from "./assets/okr-landing.png";

const OKR = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SingleProductPage
        product="OKR"
        bannerBg="#EBEBFF"
        purpose="Easily Manage Your Company"
        projectClient="OKR Management"
        view={okrBanner}
        pr={"6rem"}
        aboutProject="An enterprise OKR management portal built for one of Lagos leading consulting firms with Checking status, Company OKRs and Personal OKRs. This product was built as a SAAS but deployed as a single product for now and available in web only."
        aboutImage={okr_about}
        product_landing_page={okr_landing}
        landingBG="#224869ff"
      />
    </>
  );
};

export default OKR;
