import { Box, Img, SimpleGrid, Text } from "@chakra-ui/react";
import journey1 from "../../../../assets/journey-1.png";
import journey2 from "../../../../assets/journey-2.png";
import journey3 from "../../../../assets/journey-3.png";
import journey4 from "../../../../assets/journey-4.png";

const Journey = () => {
  return (
    <Box
      marginTop="12rem"
      padding={["3rem 2rem", "3rem 6rem"]}
      bg="transparent linear-gradient(102deg, #E4F3F6 0%, #EDECFA 100%)"
      position={"relative"}
    >
      <Text className="bold" fontSize="1.7rem" mb="60px">
        How It All Started
      </Text>

      <Box
        position={"absolute"}
        top="240px"
        data-aos="fade-up"
        display={["none", "none", "none", "block"]}
      >
        <hr
          style={{
            border: "1px solid #403e4a",
            width: "100vw",
            borderRadius: "15px",
          }}
        />
        <Box position={"relative"}>
          <Box
            position={"absolute"}
            top="-7px"
            left="0px"
            w="15px"
            h="15px"
            borderRadius={"50%"}
            bg="linear-gradient(90deg, rgba(139,139,139,1) 0%, rgba(82,80,83,1) 44%)"
          />
          <Box
            position={"absolute"}
            top="-7px"
            left="300px"
            w="15px"
            h="15px"
            borderRadius={"50%"}
            bg="linear-gradient(90deg, rgba(139,139,139,1) 0%, rgba(82,80,83,1) 44%)"
          />
          <Box
            position={"absolute"}
            top="-7px"
            left="600px"
            w="15px"
            h="15px"
            borderRadius={"50%"}
            bg="linear-gradient(90deg, rgba(139,139,139,1) 0%, rgba(82,80,83,1) 44%)"
          />
          <Box
            position={"absolute"}
            top="-7px"
            left="900px"
            w="15px"
            h="15px"
            borderRadius={"50%"}
            bg="linear-gradient(90deg, rgba(139,139,139,1) 0%, rgba(82,80,83,1) 44%)"
          />
        </Box>
      </Box>

      <SimpleGrid
        columns={[1, 3, 3, 4]}
        spacing="80px"
        justifyItems="flex-end"
        justifyContent={"space-around"}
        data-aos="fade-up"
      >
        <Box>
          <Text
            color="#280F43"
            opacity=".1"
            fontSize={["3rem", "4rem"]}
            className="bolder"
            mb="20px"
          >
            2011
          </Text>
          <Text mb={5} className="bold" fontSize="1.4rem">
            Started as Euidem Concepts
          </Text>
          <Text mb={5}>
            A website design and graphics design firm with just one founding
            member.
          </Text>
          <Img loading="lazy" src={journey1} alt="journey 1" />
        </Box>
        <Box>
          <Text
            color="#280F43"
            opacity=".1"
            fontSize={["3rem", "4rem"]}
            className="bolder"
            mb="20px"
          >
            2013
          </Text>
          <Text mb={5} className="bold" fontSize="1.4rem">
            Got registered as Euidem IT Solutions
          </Text>
          <Text mb={5}>
            With a keen focus on web development and training. The team grew to
            5 with presence in two states in Nigeria (Lagos and Oyo State)
          </Text>
          <Img loading="lazy" src={journey2} alt="journey 2" />
        </Box>
        <Box>
          <Text
            color="#280F43"
            opacity=".1"
            fontSize={["3rem", "4rem"]}
            className="bolder"
            mb="20px"
          >
            2015
          </Text>
          <Text mb={5} className="bold" fontSize="1.4rem">
            Incorporated as a Limited - Euidem Technologies
          </Text>
          <Text mb={5}>
            With services expanded into web development, mobile apps
            development, IT training, Consultancy etc.
          </Text>
          <Img loading="lazy" src={journey3} alt="journey 3" />
        </Box>
        <Box>
          <Text
            color="#280F43"
            opacity=".1"
            fontSize={["3rem", "4rem"]}
            className="bolder"
            mb="20px"
          >
            2021
          </Text>
          <Text mb={5} className="bold" fontSize="1.4rem">
            Zealight Innovation Labs was born
          </Text>
          <Text mb={5}>
            The Company grew and each of the units became individual companies.
          </Text>
          <Img loading="lazy" src={journey4} alt="journey 4" />
        </Box>
      </SimpleGrid>
      {/* </Flex> */}
    </Box>
  );
};

export default Journey;
