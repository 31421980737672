import React from "react";
import { SingleProductPage } from "../howbodi/components/SingleProductPage";
import vmsBanner from "./assets/vmsBanner.png";
import gas from "./assets/gas.png";
import admin from "./assets/admin.png";

const WMS = () => {
  return (
    <>
      <SingleProductPage
        product="Water Management System"
        bannerBg="#f3feffff"
        purpose="Vending made easy"
        projectClient="Origin Solutions"
        view={vmsBanner}
        pr={"6rem"}
        aboutProject="A smart meter vending and management platform built for origins solutions limited deployed individually for each of their clients Available on web and mobile app"
        aboutImage={gas}
        product_landing_page={admin}
        landingBG="#224869ff"
      />
    </>
  );
};

export default WMS;
