import { Box, Button, Img, Stack, Text } from "@chakra-ui/react";
import ServiceDetailHeader from "../ServiceDetailHeader";
import productDevelopment1 from "../../../../assets/product-development-1.png";
import productDevelopment2 from "../../../../assets/product-development-2.png";
import productDevelopment3 from "../../../../assets/product-development-3.png";
import productDevelopment4 from "../../../../assets/product-development-4.png";
import productDevelopment5 from "../../../../assets/product-development-5.png";
import productDevelopment6 from "../../../../assets/product-development-6.png";
import productDevelopment7 from "../../../../assets/product-development-7.png";
import productDevelopment8 from "../../../../assets/product-development-8.png";
import boxIcon1 from "../../../../assets/box-icon-1.svg";
import boxIcon2 from "../../../../assets/box-icon-2.svg";
import boxIcon3 from "../../../../assets/box-icon-3.svg";
import boxIcon4 from "../../../../assets/box-icon-4.svg";
import arrow from "../../../../assets/an-arrow.svg";
import { useNavigate } from "react-router-dom";
import ListBoxes from "../ListBoxes";
import ContactBanner from "../../../landing/components/contactBanner/contactbanner";
import { useEffect, useState } from "react";

function ProductDevelopment() {
  const [currentView, setCurrentView] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  let textRowStyle = {
    padding: ["10px 10px", "10px 10px", "20px 10px", "30px"],
    textAlign: "center",
    color: "#290F44",
  };
  const lists = [
    {
      title: "Design thinking",
      icon: boxIcon1,
      description:
        "Design thinking is a method of problem-solving that emphasizes creativity and innovation. UX/UI designers employ the design thinking approach to identify issues and generate innovative solutions by carefully considering the objectives, complaints, and end-task of their users.",
    },
    {
      title: "Design Sprint",
      icon: boxIcon2,
      description:
        "A design sprint is a technique from design thinking that entails co-creation, quick prototyping, and qualitative user testing to address complicated issues.",
    },
    {
      title: "Agile Methodology",
      icon: boxIcon3,
      description:
        "The Agile design methodology implies that designers work alongside other team members, such as developers and marketing experts. In addition to improving trust among team members, this develops a sense of participation for the project.",
    },
    {
      title: "Product Strategy",
      icon: boxIcon4,
      description:
        "Finding a successful business model, recognizing business and user experience opportunities for digital solutions including Web tools, mobile products, and web marketing, prioritizing features, and creating a launch schedule are all aspects of product planning.",
    },
  ];

  const USER_DESIGN_LIST = [
    {
      id: 1,
      title:
        "We blend user needs with business goals to help organizations make successful product",
      image: productDevelopment1,
      description:
        "We assist brands in shaping and designing products that are smart, intuitive, emotional, and long-lasting for long-term business needs through research.<br/> <br/> We use our Design-Thinking methodology to solve complex problems in collaboration with product and engineering - we understand your business, empathize with users, and provide you with the best solution to deliver results.",
    },
    {
      id: 2,
      title:
        "We create solutions and initiatives that answer business problems with creativity",
      image: productDevelopment4,
      description:
        "We like to solve problems. We assist businesses in identifying values and opportunities in order to provide strategic design solutions. We assist organizations in defining a strong direction as well as a plan and processes for creating meaningful products and experiences.",
    },
    {
      id: 3,
      title:
        "We create engaging and responsive websites full of brand personality",
      image: productDevelopment5,
      description:
        "Our Creative Designers have a wealth of experience creating smart, engaging, and minimalist websites. We work on the appearance, layout, and content of your website to create experiences that encourage people to associate with and recommend your brand.",
    },
    {
      id: 4,
      title: "We’re customer-first. We design human-centric experiences.",
      image: productDevelopment6,
      description:
        "We provide your customers with meaningful and relevant experiences. We empathize with them in order to understand their needs and pain points, which will aid us in developing potential solutions to a well-defined problem.<br/><br/> Research - user problems and product opportunity<br/> Define - personas, empathy map<br/> Ideate - sketching, wireframes, branding, UI<br/> Test - usability testing, iterations",
    },
    {
      id: 5,
      title: "We have zeal about creating beautiful and smart interfaces",
      image: productDevelopment7,
      imgToRight: true,
      description:
        "We design remarkable user interfaces that delight customers and help you achieve your digital goals. Through concept design, mock-ups, and interactive prototypes, we quickly bring your proposition and ideas to life.<br/><br/> Concept design<br/> Interaction design<br/> Design system<br/> Rapid prototyping",
    },
    {
      id: 6,
      title: "A modular and continuous process to help you grow",
      image: productDevelopment8,
      description:
        "We are equipped to expertly assess your product design and recommend areas of improvement. You will have actionable and prioritised insights into how to improve acquisition, engagement, retention and loyalty across your customer base ready to be tested.",
    },
  ];

  return (
    <Box data-aos="fade-up" bg="#fefffe" position={"relative"}>
      <ServiceDetailHeader
        subHeading={"Product Development"}
        heading={"WE TURN CHALLENGING ISSUES INTO EXQUISITE USER EXPERIENCES"}
      />
      <Img
        loading="lazy"
        src={arrow}
        alt="an arrow"
        position={"absolute"}
        left="390px"
        top="330px"
        w="30px"
      />
      <Stack
        direction={["column", "row"]}
        border="1px solid #707070"
        mb="50px"
        mt={["150px", "200px"]}
        justifyContent={"space-between"}
        fontFamily="Trenda-bold"
      >
        <Text
          {...textRowStyle}
          bg={currentView === 0 ? "#290F44" : "transparent"}
          color={currentView === 0 ? "#fff" : "initial"}
          cursor="pointer"
          onClick={() => setCurrentView(0)}
        >
          Product Design
        </Text>
        <Text
          {...textRowStyle}
          bg={currentView === 1 ? "#290F44" : "transparent"}
          color={currentView === 1 ? "#fff" : "initial"}
          cursor="pointer"
          onClick={() => setCurrentView(1)}
        >
          Design strategy
        </Text>
        <Text
          {...textRowStyle}
          bg={currentView === 2 ? "#290F44" : "transparent"}
          color={currentView === 2 ? "#fff" : "initial"}
          cursor="pointer"
          onClick={() => setCurrentView(2)}
        >
          Web Design
        </Text>
        <Text
          {...textRowStyle}
          bg={currentView === 3 ? "#290F44" : "transparent"}
          color={currentView === 3 ? "#fff" : "initial"}
          cursor="pointer"
          onClick={() => setCurrentView(3)}
        >
          User Experience
        </Text>
        <Text
          {...textRowStyle}
          bg={currentView === 4 ? "#290F44" : "transparent"}
          color={currentView === 4 ? "#fff" : "initial"}
          cursor="pointer"
          onClick={() => setCurrentView(4)}
        >
          User Unterface
        </Text>
        <Text
          {...textRowStyle}
          bg={currentView === 5 ? "#290F44" : "transparent"}
          color={currentView === 5 ? "#fff" : "initial"}
          cursor="pointer"
          onClick={() => setCurrentView(5)}
        >
          Design Optimazation
        </Text>
      </Stack>

      <Stack
        padding={["10rem 1rem 0 1rem", "0rem 6rem 0 6rem"]}
        direction={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          `${
            USER_DESIGN_LIST[currentView]?.imgToRight ? "row-reverse" : "row"
          }`,
        ]}
        spacing={"30px"}
        mb="100px"
        justifyContent={"space-between"}
        alignItems="flex-start"
      >
        <Stack maxW="550px" spacing={"20px"}>
          <Text fontFamily={"Trenda-bold"} fontSize="2em">
            {USER_DESIGN_LIST[currentView]?.title}
          </Text>
          <Text
            dangerouslySetInnerHTML={{
              __html: USER_DESIGN_LIST[currentView]?.description,
            }}
          ></Text>
          <Button
            mt="40px !important"
            bg="#290F44"
            color="#fff"
            _hover={{ background: "#290F44" }}
            alignSelf="flex-start"
            w="170px"
            boxShadow={"13px 20px 20px 0px #290f443b;"}
            onClick={() => navigate("/contact")}
          >
            CONTACT US
          </Button>
        </Stack>
        <Img
          src={USER_DESIGN_LIST[currentView]?.image}
          alt="lady holding an ipad"
        />
      </Stack>
      <Stack
        padding={["10rem 1rem 0 1rem", "10rem 6rem 0 6rem"]}
        direction={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
        ]}
        spacing={"30px"}
        mb="100px"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Stack maxW="550px" spacing={"20px"}>
          <Text fontFamily={"Trenda-heavy"} fontSize="2em">
            HOW WE GET RESULTS
          </Text>
          <Text>
            Our Design methodology offers an iterative end-to-end approach to
            designing and brainstorming effective solutions with the highest
            quality and speed. Our approach works for every stage of your
            journey, whether you are creating a new experience or optimizing an
            existing one.
          </Text>
          <Img loading="lazy" src={productDevelopment3} alt="illustration" />
        </Stack>
        <Img loading="lazy" src={productDevelopment2} alt="illustration" />
      </Stack>
      <Stack justifyContent="center" alignItems={"center"} my="50px" px="5px">
        <Text fontFamily={"Trenda-heavy"} fontSize={"2em"} color="#290F44">
          PRODUCT STRATEGIES
        </Text>
        <Text textAlign={"center"} maxW={"550px"}>
          Every project is unique, in our opinion, at Zealight. Both your team
          and your demands are dynamic. Because of this, we tackle UX procedures
          in a modular manner.
        </Text>
      </Stack>
      <ListBoxes lists={lists} />
      <ContactBanner />
    </Box>
  );
}

export default ProductDevelopment;
