import { Img, SimpleGrid, Stack, Text } from "@chakra-ui/react";

function ListBoxes({ lists }) {
  return (
    <Stack justifyContent={"center"} alignItems="center">
      <SimpleGrid
        columns={[1, 1, 2, 2]}
        spacing="50px"
        mt="50px"
        mb="100px"
        alignContent={"center"}
        justifyContent="center"
        width={["300px", "300px", "600px", "800px"]}
      >
        {lists?.map((list) => {
          return (
            <Stack
              border="2px solid #290F44"
              padding="15px"
              boxShadow="9px 9px 11px #290f441a"
              // h="300px"
            >
              <Stack direction={"row"} alignItems="center">
                {list?.icon && (
                  <Img
                    loading="lazy"
                    src={list?.icon}
                    alt="icon"
                    width={"40px"}
                    mr="20px"
                  />
                )}
                <Text fontSize={"1.8em"} fontFamily="Trenda-bold">
                  {list?.title}
                </Text>
              </Stack>
              <Text maxW={"300px"} mt="20px !important">
                {" "}
                {list.description}
              </Text>
            </Stack>
          );
        })}
      </SimpleGrid>
    </Stack>
  );
}

export default ListBoxes;
