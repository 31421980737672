import { Box, Heading, Img, SimpleGrid, Text } from "@chakra-ui/react";
import One from "../../assets/home/one.svg";
import Two from "../../assets/home/two.png";
import Three from "./home-4/one.png";
import Four from "./home-4/two.png";

import Skedu1 from "../../assets/skedulogo.png";
import Skedu2 from "../../assets/skedu.png";
import Wehaulogo from "../../assets/imgs/wehaulogo.png";
import Wehaul from "../../assets/imgs/wehaul.png";
import Skill from "../../assets/imgs/skill.png";
import Skillogo from "../../assets/imgs/skillogo.svg";
import zillLmsLogo from "../../../../assets/zil-lms-logo.png";
import zillLms1 from "../../../../assets/zil-lms-1.png";
import { useNavigate } from "react-router-dom";

export const products = [
  {
    id: 1,
    image: Four,
    title: "   Estate management software",
    logo: One,
    bgColor: "#F8F0EF",
    isCut: true,
  },
  {
    id: 2,
    logo: Skedu1,
    image: Skedu2,
    title: " Growing the value of your business",
    bgColor: "#DCE6F1",
    isCut: true,
  },

  {
    id: 3,
    logo: Two,
    image: Three,
    title: " Save time choosing when to meet in groups",
    bgColor: "#ECEFF9",
    isCut: true,
  },
  {
    id: 4,
    logo: Wehaulogo,
    image: Wehaul,
    title:
      " A platform for connecting individuals who need to move goods and products,",
    bgColor: "#BCD5FF87",
    isCut: true,
  },
  {
    id: 5,
    logo: Skillogo,
    image: Skill,
    title:
      "Skillup Africa Radio is an online radio app aimed at promoting Technology in Africa.",
    bgColor: "#E2EAFE",
  },
  {
    id: 6,
    logo: zillLmsLogo,
    image: zillLms1,
    title:
      " Zeal-LMS is a learning app with the best learning courses and great mentors.",
    bgColor: "#f5f1ed",
  },
];

const Productsx = () => {
  const navigate = useNavigate();
  return (
    <Box data-aos="fade-up" padding={["0 2rem", "0 6rem"]}>
      <Box mb="50px">
        <Heading
          marginLeft="60px"
          lineHeight="40px"
          size="lg"
          className="bold"
          fontSize={["2.7rem", "7rem"]}
          color="#290F44"
          opacity="0.1"
        >
          PRODUCTS
        </Heading>
        {/* <Flex> */}
        <Heading
          className="bold"
          maxW="850px"
          fontWeight="600"
          fontSize="1.9rem"
          color="#290F44"
          textAlign={["center", "initial"]}
        >
          SOME OF OUR PRODUCTS
        </Heading>
        {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
        {/* </Flex> */}
      </Box>

      <SimpleGrid columns={[1, null, 2]} spacing="40px">
        {products?.map((product) => {
          return (
            <Box
              flex="1"
              cursor={"pointer"}
              onClick={() => navigate(`/products/${product?.id}`)}
              data-aos="fade-right"
            >
              <Box
                display={"flex"}
                flexDirection="column"
                overflow="hidden"
                padding="3rem 0 0 2rem"
                boxShadow="0px 3px 6px #00000012"
                flex="1"
                position="relative"
                bg={product?.bgColor}
                backgroundSize="cover"
                height={["400px", "500px"]}
                // maxWidth="560px"
                borderRadius="5px"
              >
                <Box className="overlay" borderRadius="15px"></Box>
                <Img
                  loading="lazy"
                  position="relative"
                  right={"0px !important"}
                  width={["100px", "150px"]}
                  src={product?.logo}
                  alt="product logo"
                />
                <Text
                  lineHeight="26px"
                  mt={7}
                  color="#100120"
                  fontSize="1.2rem"
                  maxW={350}
                >
                  {product?.title}
                </Text>

                {product?.mobileImgDevice ? (
                  <Box>
                    {" "}
                    <Img
                      loading="lazy"
                      // position="absolute"
                      src={product?.image}
                      alt="product image"
                      left="40px !important"
                      bottom="0"
                    />
                    <Img
                      loading="lazy"
                      position="absolute"
                      width="40%"
                      // left="20px !important"
                      h="300px"
                      alignSelf={"flex-end"}
                      bottom={["-20px", "7%"]}
                      src={product?.mobileImgDevice}
                      objectFit={"contain"}
                    />
                  </Box>
                ) : (
                  <Img
                    loading="lazy"
                    // w="300px"
                    position="relative"
                    src={product?.image}
                    alt="product image111"
                    right={
                      product?.isCut ? "0px !important" : "40px !important"
                    }
                    bottom="0"
                    h="300px"
                    alignSelf={"flex-end"}
                    objectFit={"contain"}
                  />
                )}

                {/* <Box position="absolute" bottom="3%" left="5%">
                  <Box>
                    <IconButton
                      _hover={{ background: "#00FE98" }}
                      colorScheme="purple"
                      aria-label="Search database"
                      height={7}
                      icon={<ArrowForwardIcon />}
                      onClick={() => navigate(`/products/${product?.id}`)}
                    />
                  </Box>
                </Box> */}
              </Box>
              {/* <Text fontSize="1.2rem" mt={6} textDecoration="underline">Web Application</Text>
                <Heading mt={3} className="bold" letterSpacing="1.39px" maxW="850px" fontWeight="600" fontSize="1.7rem" color="#290F44">FIXMEET</Heading> */}
            </Box>
          );
        })}
      </SimpleGrid>

      {/* <Flex justifyContent="center">
                <Button margin="4rem auto" width={170} padding="1.5rem 3rem" borderRadius="5px" bg="#290F44" color="#fff" fontSize="14px">VIEW ALL PRODUCTS</Button>
            </Flex> */}
    </Box>
  );
};

export default Productsx;
