import { Divider, Img, SimpleGrid } from "@chakra-ui/react";
import One from "../../assets/home/one.svg";
import Two from "../../assets/home/two.png";
import Three from "../../assets/home/three.png";
import zillLmsLogo from "../../../../assets/zil-lms-logo.png";
import collegeApp from "../../../../assets/college_app.jpeg";

const ProductsBanner = () => {
  return (
    <>
      <SimpleGrid
        minChildWidth={["60px", "120px"]}
        spacing="20px"
        data-aos="fade-right"
        alignItems="center"
        padding={["1.5em", "2.5rem 6rem"]}
      >
        <Img
          loading="lazy"
          filter="grayscale(100%)"
          width={["100%", "50%"]}
          src={zillLmsLogo}
        />
        <Img
          loading="lazy"
          filter="grayscale(100%)"
          width={["100%", "70%"]}
          src={One}
        />
        <Img
          loading="lazy"
          filter="grayscale(100%)"
          width={["100%", "70%"]}
          src={Two}
        />
        <Img
          loading="lazy"
          filter="grayscale(100%)"
          width={["100%", "70%"]}
          src={Three}
        />

        <Img
          loading="lazy"
          filter="grayscale(100%)"
          width={["100%", "70%"]}
          src={collegeApp}
        />
      </SimpleGrid>
      <Divider />
    </>
  );
};

export default ProductsBanner;
