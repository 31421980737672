import {
  Box,
  Button,
  Heading,
  Img,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { TEAMS_LIST } from "../../../teams";

const Team = () => {
  const [currentMouseLocation, setCurrentMouseLocation] = useState(null);
  const navigate = useNavigate();
  return (
    <Box>
      <Stack
        direction={["column", "row"]}
        data-aos="fade-up"
        marginTop="7rem"
        marginBottom="2rem"
        alignItems="center"
        justifyContent="space-between"
        gap="2rem"
        padding="6rem 6rem 0 6rem"
      >
        <Box>
          <Heading
            marginLeft="90px"
            lineHeight="40px"
            size="lg"
            className="bolder"
            fontSize={["5rem", "7rem"]}
            color="#290F44"
            opacity="0.1"
          >
            TEAM
          </Heading>
          {/* <Flex> */}
          <Heading
            className="bold"
            maxW="550px"
            fontWeight="600"
            fontSize="1.9rem"
            color="#290F44"
          >
            LEARN MORE ABOUT THE PEOPLE WHO MAKE US AWESOME
          </Heading>
          {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
          {/* </Flex> */}
        </Box>
        <Button
          mt={10}
          width={170}
          padding="1.5rem 3rem"
          borderRadius="5px"
          bg="#290F44"
          _hover={{ background: "#00FE98" }}
          color="#fff"
          fontSize="14px"
          onClick={() => navigate("/teams")}
          boxShadow={"13px 20px 20px 0px #290f443b;"}
        >
          VIEW TEAMS
        </Button>
      </Stack>

      {/* <Flex padding="2rem 6rem 0 6rem" justifyContent="space-between"> */}
      <SimpleGrid
        padding={["2rem 2rem 0 2rem", "2rem 6rem 0 6rem"]}
        columns={[2, 2, 2, 4]}
        spacing="40px"
      >
        {TEAMS_LIST?.slice(8, 12)?.map((team, id) => (
          <Box
            key={id}
            position={"relative"}
            onMouseOver={() => setCurrentMouseLocation(id)}
            onMouseOut={() => setCurrentMouseLocation(null)}
          >
            <Img
              loading="lazy"
              w="300px"
              height={"300px"}
              borderRadius={"20px"}
              objectFit={"cover"}
              src={team?.image}
              alt={team?.role}
              zIndex={currentMouseLocation === id ? 0 : 999}
            />
            <Stack
              borderRadius={"20px"}
              bg="#000000ba"
              display={currentMouseLocation === id ? "flex" : "none"}
              zIndex={currentMouseLocation === id ? 999 : 0}
              position="absolute"
              top="0"
              left="0"
              w="100%"
              h="100%"
              color="#fff"
              justifyContent="flex-end"
              alignItems="baseline"
              p="10px"
              fontSize={".8em"}
            >
              <Text fontWeight={"bold"}>{team.name}</Text>
              <Text maxW="120px">{team.role}</Text>
            </Stack>
          </Box>
        ))}
      </SimpleGrid>
      {/* </Flex> */}
    </Box>
  );
};

export default Team;
