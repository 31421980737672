import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, IconButton, Text } from "@chakra-ui/react";
import One from "../../../services/assets/Service/one.png";
import Two from "../../../services/assets/Service/two.png";
import Three from "../../../services/assets/Service/three.png";
import Four from "../../../services/assets/Service/four.png";
import Five from "../../../services/assets/Service/five.png";
import "./servicecard.scss";
import { useNavigate } from "react-router-dom";

const ServicesCards = () => {
  const navigate = useNavigate();

  const scrollRight = () => {
    let obj = document.getElementById("review-scroll");
    obj.scrollBy(400, 0);
  };
  const scrollLeft = () => {
    let obj = document.getElementById("review-scroll");
    obj.scrollBy(-400, 0);
  };

  return (
    <>
      <Flex
        gap="2rem"
        padding={["2rem 1rem", "2rem 6rem"]}
        overflowX="scroll"
        className="scroll"
        id="review-scroll"
        scrollBehavior={"smooth"}
      >
        <Box
          data-aos="fade-right"
          flex="1"
          className="card"
          position="relative"
          backgroundImage={One}
          backgroundSize="cover"
          height="320px"
          minWidth="300px"
          borderRadius="15px"
        >
          <Box className="overlay" borderRadius="15px"></Box>
          <Box position="absolute" bottom="3%" left="5%">
            <Heading
              marginBottom=".7rem"
              fontSize="22px"
              fontWeight="700"
              color="#fff"
            >
              PRODUCT <br /> DEVELOPMENT
            </Heading>
            <Box className="more">
              <Text marginBottom=".7rem" display="" color="#fff">
                We have over a decade experience in Product Development, Our
                team efficiently builds viable software products for our
                clients.
              </Text>
              <IconButton
                _hover={{ background: "#00FE98" }}
                colorScheme="purple"
                aria-label="Search database"
                height={7}
                icon={<ArrowForwardIcon />}
                onClick={() => navigate("/service/product-development")}
              />
            </Box>
          </Box>
        </Box>
        <Box
          data-aos="fade-right"
          flex="1"
          className="card"
          position="relative"
          backgroundImage={Two}
          backgroundSize="cover"
          height="320px"
          minWidth="300px"
          borderRadius="15px"
        >
          <Box className="overlay" borderRadius="15px"></Box>
          <Box position="absolute" bottom="3%" left="5%">
            <Heading
              marginBottom=".7rem"
              fontSize="22px"
              fontWeight="700"
              color="#fff"
            >
              UX <br /> RESEARCH
            </Heading>
            <Box className="more">
              <Text marginBottom=".7rem" display="" color="#fff">
                Our Product design unit focuses on in-depth Ux research to help
                design your products with the best product market fit.
              </Text>
              <IconButton
                _hover={{ background: "#00FE98" }}
                colorScheme="purple"
                aria-label="Search database"
                height={7}
                icon={<ArrowForwardIcon />}
                onClick={() => navigate("/service/ux-research")}
              />
            </Box>
          </Box>
        </Box>
        <Box
          data-aos="fade-right"
          flex="1"
          className="card"
          position="relative"
          backgroundImage={Three}
          backgroundSize="cover"
          height="320px"
          minWidth="300px"
          borderRadius="15px"
        >
          <Box className="overlay" borderRadius="15px"></Box>
          <Box position="absolute" bottom="3%" left="5%">
            <Heading
              marginBottom=".7rem"
              fontSize="22px"
              fontWeight="700"
              color="#fff"
            >
              I.T <br /> CONSULTANT
            </Heading>
            <Box className="more">
              <Text marginBottom=".7rem" display="" color="#fff">
                Leverage our experience in both business development, growth
                hacking, product development and startup with out consultancy
                service.
              </Text>
              <IconButton
                _hover={{ background: "#00FE98" }}
                colorScheme="purple"
                aria-label="Search database"
                height={7}
                icon={<ArrowForwardIcon />}
                onClick={() => navigate("/service/it-consultancy")}
              />
            </Box>
          </Box>
        </Box>
        <Box
          data-aos="fade-right"
          flex="1"
          className="card"
          position="relative"
          backgroundImage={Four}
          backgroundSize="cover"
          height="320px"
          minWidth="300px"
          borderRadius="15px"
        >
          <Box className="overlay" borderRadius="15px"></Box>
          <Box position="absolute" bottom="3%" left="5%">
            <Heading
              marginBottom=".7rem"
              fontSize="22px"
              fontWeight="700"
              color="#fff"
            >
              QUALITY <br /> ASSURANCE
            </Heading>
            <Box className="more">
              <Text marginBottom=".7rem" display="" color="#fff">
                Rethink your company's offering with a digital-first strategy,
                make procedures more efficient, and improve client experiences.
              </Text>
              <IconButton
                _hover={{ background: "#00FE98" }}
                colorScheme="purple"
                aria-label="Search database"
                height={7}
                icon={<ArrowForwardIcon />}
                onClick={() => navigate("/service/quality-assurance")}
              />
            </Box>
          </Box>
        </Box>
        <Box
          data-aos="fade-right"
          flex="1"
          className="card"
          position="relative"
          backgroundImage={Five}
          backgroundSize="cover"
          height="320px"
          minWidth="300px"
          borderRadius="15px"
        >
          <Box className="overlay" borderRadius="15px"></Box>
          <Box position="absolute" bottom="3%" left="5%">
            <Heading
              marginBottom=".7rem"
              fontSize="22px"
              fontWeight="700"
              color="#fff"
            >
              TALENT <br /> OUTSOURCING
            </Heading>
            <Box className="more">
              <Text marginBottom=".7rem" display="" color="#fff">
                Get well trained and experienced Developers, Designers &
                Managers on your team on full-time or co-managed basis.
              </Text>
              <IconButton
                _hover={{ background: "#00FE98" }}
                colorScheme="purple"
                aria-label="Search database"
                height={7}
                icon={<ArrowForwardIcon />}
                onClick={() => navigate("/service/talent-outsourcing")}
              />
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex mb={1} mt={1} justifyContent="center">
        <Box>
          <ArrowBackIcon
            fontSize="1.8rem"
            marginRight={4}
            onClick={scrollLeft}
            cursor="pointer"
          />
          <ArrowForwardIcon
            fontSize="1.8rem"
            onClick={scrollRight}
            cursor="pointer"
          />
        </Box>
      </Flex>
    </>
  );
};

export default ServicesCards;
