import { Box, Img, Text } from "@chakra-ui/react";
import Bg from "../../../../assets/gradientbg.svg";
import IncludeBallAnimation from "../../../../components/IncludeBallAnimation";
import Arrow from "../../assets/arrow/arrow.png";

const Hero = () => {
  return (
    <Box
      position="relative"
      backgroundImage={Bg}
      padding={["5.5rem 2rem 0 2rem", "5.5rem 6rem 0 6rem"]}
      height="100vh"
      backgroundSize="cover"
    >
      <Img
        loading="lazy"
        width="4%"
        className="arrow"
        position="absolute"
        top="55%"
        right="23%"
        src={Arrow}
      />
      <IncludeBallAnimation />

      <Box data-aos="fade-up">
        <Text marginTop="140px" fontSize="1.4rem" color="#290F44">
          PORTFOLIO
        </Text>
        <Text
          marginTop="1.5rem"
          maxWidth="700px"
          lineHeight={["50px", "80px"]}
          fontSize={["2rem", "4rem"]}
          className="bolder"
          color="#290F44"
        >
          INTERESTED IN OUR PREVIOUS WORK? TAKE A LOOK
        </Text>
        <Text
          marginTop="1.5rem"
          maxWidth="800px"
          fontWeight="500"
          fontSize="1.4rem"
          color="#290F44"
        >
          <strong>Take a peek at some of our projects.</strong>
        </Text>
      </Box>
    </Box>
  );
};

export default Hero;
