import { Box, Heading, Img } from "@chakra-ui/react";
import { useEffect } from "react";
import Blog from "../blog/blog";
import Clients from "./components/clients/clients";
import ContactBanner from "./components/contactBanner/contactbanner";
import Hero from "./components/hero";
import Products from "./components/products/products";
import ProductsBanner from "./components/productsBanner/productsBanner";
import Reviews from "./components/reviews/reviews";
import TurnoverBanner from "./components/turnoverBanner/turnoverBanner";
import WhoServices from "./components/whoServices/whoServices";
import Why from "./components/why/why";

import partner1 from "../../assets/partner-googleLogo.png";
import partner2 from "../../assets/partner-honeywealth-logo.png";
import partner3 from "../../assets/partner-logo.png";
import partner4 from "../../assets/skill-radio-2.png";
import partner5 from "../../assets/college_app.jpeg";

import InfiniteCarousel from "react-leaf-carousel";
const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className="landinf">
      <Hero />
      <ProductsBanner />
      <WhoServices />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <TurnoverBanner />
      <Why />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Products />
      <br />
      <br />
      <br />
      <br />
      <Clients />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Reviews />
      <br />
      <br />
      <br />
      <br />
      <ContactBanner />
      <Blog />

      <Box data-aos="fade-up" padding={["0 2rem", "0 6rem"]} mb="40px">
        <Heading
          textAlign="center"
          className="bold"
          maxW="850px"
          margin="0 auto"
          fontWeight="600"
          fontSize={["1.5em", "1.9rem"]}
          color="#290F44"
          mb="50px"
        >
          PARTNERS
        </Heading>

        <InfiniteCarousel
          autoCycle={true}
          arrows={false}
          cycleInterval={2000}
          breakpoints={[
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ]}
          dots={false}
          sidesOpacity={0.5}
          sideSize={0.1}
          slidesToScroll={3}
          slidesToShow={3}
        >
          <Box
            alignSelf="center"
            w="300px"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Img
              w="100px"
              h="100px"
              objectFit={"contain"}
              loading="lazy"
              src={partner1}
            />
          </Box>
          <Box
            alignSelf="center"
            w="300px"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Img
              w="100px"
              h="100px"
              objectFit={"contain"}
              loading="lazy"
              src={partner2}
            />
          </Box>
          <Box
            alignSelf="center"
            w="300px"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Img
              w="100px"
              h="100px"
              objectFit={"contain"}
              loading="lazy"
              src={partner3}
            />
          </Box>
        </InfiniteCarousel>
      </Box>
    </Box>
  );
};

export default Landing;
