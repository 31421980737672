import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import ArrowButton from "../../../../components/animatedArrow/animatedArrowButton";
import One from "../../assets/Service/one.png";
import Two from "../../assets/Service/two.png";
import Three from "../../assets/Service/three.png";
import Four from "../../assets/Service/four.png";
import Five from "../../assets/Service/five.png";
import Six from "../../assets/Service/six.png";
import { useNavigate } from "react-router-dom";

const ServicesCards = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Stack
        bg="#F6F8FF"
        direction={["column-reverse", "row"]}
        maxH={["", "650px"]}
      >
        <Box
          data-aos="fade-right"
          padding={["3rem 2rem", "3rem 6rem"]}
          flex="1"
        >
          <Box marginTop="8rem">
            <Heading
              lineHeight="10px"
              size="lg"
              className="bolder"
              fontSize="4rem"
              color="#290F44"
              opacity="0.1"
            >
              01
            </Heading>
            {/* <Flex> */}
            <Heading
              fontWeight="800"
              maxWidth="300px"
              className="bold"
              fontSize="2.2rem"
              color="#290F44"
            >
              Product Development
            </Heading>
            {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
            {/* </Flex> */}
          </Box>
          <Text
            marginBottom="2rem"
            maxW="450px"
            marginTop="2.5rem"
            fontSize="1.2rem"
            color="#434144"
          >
            We over a decade experience in Product Development, our team
            efficiently build viable software products for our clients.
          </Text>
          <ArrowButton
            text="Learn More"
            color="#05C97A"
            onClick={() => navigate("/service/product-development")}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Box>
        <Box
          data-aos="fade-left"
          backgroundImage={One}
          backgroundSize="cover"
          padding={["3rem 2rem", "3rem 6rem"]}
          flex={["", "1"]}
          h={["400px", "650px"]}
          maxH="650px"
        ></Box>
      </Stack>
      <Stack
        bg="#F6F8FF"
        direction={["column-reverse", "row-reverse"]}
        maxH={["", "650px"]}
      >
        <Box data-aos="fade-left" padding={["3rem 2rem", "3rem 6rem"]} flex="1">
          <Box marginTop="8rem">
            <Heading
              lineHeight="10px"
              size="lg"
              className="bolder"
              fontSize="4rem"
              color="#290F44"
              opacity="0.1"
            >
              02
            </Heading>
            {/* <Flex> */}
            <Heading
              fontWeight="800"
              maxWidth="300px"
              className="bold"
              fontSize="2.2rem"
              color="#290F44"
            >
              UX Research
            </Heading>
            {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
            {/* </Flex> */}
          </Box>
          <Text
            marginBottom="2rem"
            maxW="450px"
            marginTop="2.5rem"
            fontSize="1.2rem"
            color="#434144"
          >
            Our Product Design Unit focuses on in-depth UX research to help
            design your products with the best product-market fit.
          </Text>
          <ArrowButton
            text="Learn More"
            color="#05C97A"
            onClick={() => navigate("/service/ux-research")}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Box>
        <Box
          data-aos="fade-right"
          padding={["3rem 2rem", "3rem 6rem"]}
          backgroundImage={Two}
          backgroundSize="cover"
          flex={["", "1"]}
          h={["400px", "650px"]}
        ></Box>
      </Stack>
      <Stack
        bg="#F6F8FF"
        direction={["column-reverse", "row"]}
        maxH={["", "650px"]}
      >
        <Box
          data-aos="fade-right"
          padding={["3rem 2rem", "3rem 6rem"]}
          flex="1"
        >
          <Box marginTop="8rem">
            <Heading
              lineHeight="10px"
              size="lg"
              className="bolder"
              fontSize="4rem"
              color="#290F44"
              opacity="0.1"
            >
              03
            </Heading>
            {/* <Flex> */}
            <Heading
              fontWeight="800"
              maxWidth="300px"
              className="bold"
              fontSize="2.2rem"
              color="#290F44"
            >
              I.T Consultancy
            </Heading>
            {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
            {/* </Flex> */}
          </Box>
          <Text
            marginBottom="2rem"
            maxW="450px"
            marginTop="2.5rem"
            fontSize="1.2rem"
            color="#434144"
          >
            Leverage our experience in both business development, growth
            hacking, product development and startups by engaging our
            consultancy services.
          </Text>
          <ArrowButton
            text="Learn More"
            color="#05C97A"
            onClick={() => navigate("/service/it-consultancy")}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Box>
        <Box
          data-aos="fade-left"
          backgroundImage={Three}
          backgroundSize="cover"
          padding={["3rem 2rem", "3rem 6rem"]}
          flex={["", "1"]}
          h={["400px", "650px"]}
        ></Box>
      </Stack>
      <Stack
        bg="#F6F8FF"
        direction={["column-reverse", "row-reverse"]}
        maxH={["", "650px"]}
      >
        <Box data-aos="fade-left" padding={["3rem 2rem", "3rem 6rem"]} flex="1">
          <Box marginTop="8rem">
            <Heading
              lineHeight="10px"
              size="lg"
              className="bolder"
              fontSize="4rem"
              color="#290F44"
              opacity="0.1"
            >
              04
            </Heading>
            {/* <Flex> */}
            <Heading
              fontWeight="800"
              maxWidth="300px"
              className="bold"
              fontSize="2.2rem"
              color="#290F44"
            >
              Quality Assurance
            </Heading>
            {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
            {/* </Flex> */}
          </Box>
          <Text
            marginBottom="2rem"
            maxW="450px"
            marginTop="2.5rem"
            fontSize="1.2rem"
            color="#434144"
          >
            Rethink your company's offering with a digital-first strategy, make
            procedures more efficient, and improve client experiences.
          </Text>
          <ArrowButton
            text="Learn More"
            color="#05C97A"
            onClick={() => navigate("/service/quality-assurance")}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Box>
        <Box
          data-aos="fade-right"
          backgroundImage={Four}
          backgroundSize="cover"
          padding={["3rem 2rem", "3rem 6rem"]}
          flex={["", "1"]}
          h={["400px", "650px"]}
        ></Box>
      </Stack>
      <Stack
        bg="#F6F8FF"
        direction={["column-reverse", "row"]}
        maxH={["", "650px"]}
      >
        <Box
          data-aos="fade-right"
          padding={["3rem 2rem", "3rem 6rem"]}
          flex="1"
        >
          <Box marginTop="8rem">
            <Heading
              lineHeight="10px"
              size="lg"
              className="bolder"
              fontSize="4rem"
              color="#290F44"
              opacity="0.1"
            >
              05
            </Heading>
            {/* <Flex> */}
            <Heading
              fontWeight="800"
              maxWidth="300px"
              className="bold"
              fontSize="2.2rem"
              color="#290F44"
            >
              Mobile App Development
            </Heading>
            {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
            {/* </Flex> */}
          </Box>
          <Text
            marginBottom="2rem"
            maxW="450px"
            marginTop="2.5rem"
            fontSize="1.2rem"
            color="#434144"
          >
            Create mobile apps with excellent usability and unique features,
            whether they are native or hybrid (cross-platform).
          </Text>
          <ArrowButton
            text="Learn More"
            color="#05C97A"
            onClick={() => navigate("/service/mobile-app-development")}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Box>
        <Box
          data-aos="fade-left"
          backgroundImage={Five}
          backgroundSize="cover"
          padding={["3rem 2rem", "3rem 6rem"]}
          flex={["", "1"]}
          h={["400px", "650px"]}
        ></Box>
      </Stack>
      <Stack
        bg="#F6F8FF"
        direction={["column-reverse", "row-reverse"]}
        maxH={["", "650px"]}
      >
        <Box data-aos="fade-left" padding={["3rem 2rem", "3rem 6rem"]} flex="1">
          <Box marginTop="8rem">
            <Heading
              lineHeight="10px"
              size="lg"
              className="bolder"
              fontSize="4rem"
              color="#290F44"
              opacity="0.1"
            >
              06
            </Heading>
            {/* <Flex> */}
            <Heading
              fontWeight="800"
              maxWidth="300px"
              className="bold"
              fontSize="2.2rem"
              color="#290F44"
            >
              Talent Outsourcing
            </Heading>
            {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
            {/* </Flex> */}
          </Box>
          <Text
            marginBottom="2rem"
            maxW="450px"
            marginTop="2.5rem"
            fontSize="1.2rem"
            color="#434144"
          >
            Get well trained and experienced Developers, Designers and Managers
            for your team on full-time or co-managed basis.
          </Text>
          <ArrowButton
            text="Learn More"
            color="#05C97A"
            onClick={() => navigate("/service/talent-outsourcing")}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Box>
        <Box
          data-aos="fade-right"
          backgroundImage={Six}
          backgroundSize="cover"
          padding={["3rem 2rem", "3rem 6rem"]}
          flex={["", "1"]}
          h={["400px", "650px"]}
        ></Box>
      </Stack>
    </Box>
  );
};

export default ServicesCards;
