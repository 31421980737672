import { Heading, Stack, Text } from "@chakra-ui/react";

function ServiceDetailHeader({ heading, subHeading }) {
  return (
    <Stack
      maxW="750px"
      padding={["10rem 1rem 0 1rem", "10rem 6rem 0 6rem"]}
      mb="90px"
    >
      <Text>{subHeading}</Text>
      <Stack direction={"row"} alignItems="flex-end">
        <Heading
          fontFamily={"Trenda-heavy"}
          fontSize="2.2em"
          lineHeight={1.5}
          position="relative"
        >
          {heading}
          <span style={{ color: "#00FE98", position: "absolute", bottom: 0 }}>
            &#8226;
          </span>
        </Heading>
      </Stack>
    </Stack>
  );
}

export default ServiceDetailHeader;
