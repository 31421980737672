import {
  Box,
  Button,
  Flex,
  FormControl,
  Img,
  Input,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import lottiAnime from "../../assets/contact-anime";
import bridge from "../../assets/contact.png";
import Lottie from "react-lottie";
import ZealightWatermark from "../../components/ZealightWatermark";
import Elipses from "../../components/Elipses";
import { useState } from "react";

const Contact = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  let formValues = {
    name: "",
    message: "",
    phoneNumber: "",
    companyName: "",
    email: "",
  };
  const [form, setForm] = useState(formValues);
  const [disabled, setDisabled] = useState(true);
  const [scrollTop, setScrollTop] = useState({});
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottiAnime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [scrollTop]);

  useEffect(() => {
    const { name, email, phoneNumber, companyName, message } = form || {};
    if (!name || !email || !phoneNumber || !companyName || !message) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [form]);
  // const api = async () => {
  //   return fetch("https://zealight-website.herokuapp.com/contact-us", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(form),
  //   });
  // };

  // console.log(form, "nn", formValues);

  const api = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", form.name);
    formData.append("email", form.email);
    formData.append("phoneNumber", form.phoneNumber);

    formData.append("companyName", form.companyName);
    // formData.append("specialty", formValues.address);
    formData.append("message", form.message);
    return fetch(
      "https://formsubmit.co/ajax/936a2f8a62888ecca82e3e5d9290df3c",
      {
        method: "POST",
        //   headers: {
        //     // "Content-Type": "multipart/form-data",
        //     // Accept: "application/json",
        //   },
        body: formData,
      }
    );
  };

  const sendInfo = async () => {
    setLoading(true);

    try {
      await api();
      setLoading(false);
      toast({
        description:
          "We have received your message, & we'll get back to you soon.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom",
      });
      setForm(formValues);
      setScrollTop({});
    } catch (e) {
      setLoading(false);
      toast({
        description: "Something went wrong, please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  return (
    <Box data-aos="fade-up" position={"relative"} paddingTop="5.5rem">
      <ZealightWatermark />
      <Elipses />

      <Flex
        justifyContent={"center"}
        padding={["5.5rem 2rem 0 2rem", "5.5rem 6rem 0 6rem"]}
      >
        {" "}
        <Box mt="4rem" mb="6rem" color={"#100120"} textAlign={"center"}>
          <Stack direction="row" alignItems={"flex-start"} position="relative">
            <Lottie
              options={defaultOptions}
              height={70}
              width={150}
              style={{ margin: "0 !important" }}
            />

            <Text
              className="bolder"
              fontSize={["2rem", "4rem"]}
              position="absolute"
              right={["initial", "90px"]}
              top={["30px", "0"]}
            >
              GET IN TOUCH
              <Box
                // ml="2px"
                display={"inline-block"}
                w="10px"
                h="10px"
                borderRadius="50%"
                bg="#00FE98"
              />
            </Text>
          </Stack>
          <Text maxW={"660px"} fontSize={"1.2rem"} mt="20px">
            If you're looking for a digital partner to build something awesome
            together, we'd love to hear from you, let's get started.
          </Text>
        </Box>
      </Flex>
      <Box
        maxW={"800px"}
        margin={["0 auto 4rem auto"]}
        p={["20px", "20px", "20px", "0px"]}
      >
        <form style={{ position: "relative", zIndex: "999" }}>
          <FormControl mb={8}>
            <label className="bold">Name</label>
            <Input
              type="text"
              padding={["1rem", "1.5rem"]}
              box-shadow="0px 3px 6px #00000005"
              border="1px solid #18052D2E"
              borderRadius="6px"
              value={form?.name}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </FormControl>
          <FormControl mb={8}>
            <label className="bold">Email Address</label>
            <Input
              type="email"
              padding={"1.5rem"}
              box-shadow="0px 3px 6px #00000005"
              border="1px solid #18052D2E"
              borderRadius="6px"
              value={form?.email}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, email: e.target.value }))
              }
            />
          </FormControl>
          <FormControl mb={8}>
            <label className="bold">Contact Number</label>
            <Input
              type="number"
              padding={"1.5rem"}
              box-shadow="0px 3px 6px #00000005"
              border="1px solid #18052D2E"
              borderRadius="6px"
              value={form?.phoneNumber}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, phoneNumber: e.target.value }))
              }
            />
          </FormControl>
          <FormControl mb={8}>
            <label className="bold">Company Name</label>
            <Input
              type="text"
              padding={"1.5rem"}
              box-shadow="0px 3px 6px #00000005"
              border="1px solid #18052D2E"
              borderRadius="6px"
              value={form?.companyName}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, companyName: e.target.value }))
              }
            />
          </FormControl>
          <FormControl mb={8}>
            <label className="bold">
              Tell Us About Your Project/ Drop a message
            </label>
            <Textarea
              type="text"
              rows={"7"}
              padding={"1.5rem"}
              box-shadow="0px 3px 6px #00000005"
              border="1px solid #18052D2E"
              borderRadius="6px"
              value={form?.message}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, message: e.target.value }))
              }
            />
          </FormControl>
          <Flex justifyContent="center">
            <Button
              width={"180px"}
              padding="1.3rem .5rem"
              bg={["#290F44"]}
              _hover={{ background: "#290F44" }}
              color="#fff"
              isLoading={loading}
              disabled={disabled}
              onClick={sendInfo}
            >
              SUBMIT
            </Button>
          </Flex>
        </form>
      </Box>
      <Box position={"relative"} zIndex="333" bg={"#F6F6F6"}>
        <SimpleGrid
          columns={[2, null, 3]}
          spacing="40px"
          // bg={"#F6F6F6"}
          padding={["3rem 2rem 1rem 2rem", "3rem 10rem 1rem 10rem"]}
        >
          <Img
            loading="lazy"
            zIndex="222"
            position={"absolute"}
            right="0"
            src={bridge}
            alt="bridge"
            bottom={"0px"}
          />
          <Box>
            <Text fontSize={"1.2rem"} color="#AAAAAA" fontWeight={"600"} mb={2}>
              Our Office
            </Text>
            <Text fontSize={"1.2rem"}>
              25b Marigold Street, <br /> Emerald Estate, <br /> Ajah Lagos
              State, <br /> Nigeria
            </Text>
          </Box>
          <Box>
            <Text fontSize={"1.2rem"} color="#AAAAAA" fontWeight={"600"} mb={2}>
              Tel
            </Text>
            <Text>+234 703 262 3818</Text>
          </Box>
          <Box>
            <Text fontSize={"1.2rem"} color="#AAAAAA" fontWeight={"600"} mb={2}>
              Company Email
            </Text>
            <a
              href="http://mailto:zealightlabs@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text textDecoration={"underline"}>Zealightlabs@gmail.com</Text>
            </a>
          </Box>
        </SimpleGrid>
        <Text
          color={"#100120"}
          cursor="pointer"
          textDecoration="underline"
          fontSize={"1.2rem"}
          padding={["0 7rem 2rem 7rem", "0 10rem 2rem 10rem"]}
          position="relative"
          zIndex={"333"}
        >
          Get Direction
        </Text>
      </Box>
    </Box>
  );
};

export default Contact;
