import { Box, Flex, Heading, Img } from "@chakra-ui/react";
import Map from "./home-5/map.svg";
import One from "./home-5/one.png";
import Two from "./home-5/two.png";
import Three from "./home-5/three.png";
import Four from "./home-5/four.png";
import Five from "./home-5/five.png";
import Six from "./home-5/six.png";
import ReviewCards from "./reviewCards/reviewcards";

const Reviews = () => {
  return (
    <Box data-aos="fade-up" padding={["0 2rem", "0 6rem"]}>
      <Flex justifyContent="center">
        <Box>
          <Heading
            marginLeft="25px"
            lineHeight="40px"
            size="lg"
            className="bold"
            fontSize={["5em", "7rem"]}
            color="#290F44"
            opacity="0.1"
          >
            REVIEWS
          </Heading>
          {/* <Flex> */}
          <Heading
            textAlign="center"
            className="bold"
            maxW="550px"
            fontWeight="600"
            fontSize="1.9rem"
            color="#290F44"
          >
            WE HAVE CLIENTS WORLDWIDE HEAR WHAT THEY SAY
          </Heading>
          {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
          {/* </Flex> */}
        </Box>
      </Flex>
      <br />
      <br />
      <br />
      <Box position="relative">
        <Img loading="lazy" width="90%" margin="0 auto" src={Map} />
        <Img
          loading="lazy"
          top="8%"
          left="13%"
          width="6%"
          position="absolute"
          src={One}
        />
        <Img
          loading="lazy"
          top="0%"
          left="43%"
          width="6%"
          position="absolute"
          src={Two}
        />
        <Img
          loading="lazy"
          bottom="38%"
          left="30%"
          width="6%"
          position="absolute"
          src={Three}
        />
        <Img
          loading="lazy"
          bottom="41%"
          left="50%"
          width="6%"
          position="absolute"
          src={Four}
        />
        <Img
          loading="lazy"
          top="20%"
          right="27%"
          width="6%"
          position="absolute"
          src={Five}
        />
        <Img
          loading="lazy"
          bottom="34%"
          right="15%"
          width="6%"
          position="absolute"
          src={Six}
        />
      </Box>

      <ReviewCards />
    </Box>
  );
};

export default Reviews;
