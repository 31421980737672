import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import "./animatedArrow.scss";

const ArrowButton = ({ text, color, ...props }) => {
  return (
    <Flex
      className="ab"
      cursor="pointer"
      fontWeight="600"
      color={color}
      alignItems="center"
      gap={2}
      {...props}
    >
      <Text className="t">{text}</Text>
      <ArrowForwardIcon className="a" />
    </Flex>
  );
};

export default ArrowButton;
