import { Box, Stack, Text } from "@chakra-ui/react";
import Man from "../../assets/about/man.png";

const Who = () => {
  return (
    <Box>
      <Stack direction={["column", "row"]} bg="#F6F8FF">
        <Box flex="1" data-aos="fade-right">
          <Box
            padding={["3rem 2rem", "3rem 2rem", "3rem 2rem", "3rem 4rem"]}
            boxSizing="border-box"
          >
            <Text mb={4} className="bolder" fontSize="2rem">
              We are Innovators{" "}
              <Box
                // ml="2px"
                display={"inline-block"}
                w="7px"
                h="7px"
                borderRadius="50%"
                bg="#00FE98"
              />
            </Text>
            <Text mb={4} className="bolder" fontSize="2rem">
              Collaborators{" "}
              <Box
                // ml="2px"
                display={"inline-block"}
                w="7px"
                h="7px"
                borderRadius="50%"
                bg="#00FE98"
              />
            </Text>
            <Text className="bolder" fontSize="2rem">
              Creators{" "}
              <Box
                // ml="2px"
                display={"inline-block"}
                w="7px"
                h="7px"
                borderRadius="50%"
                bg="#00FE98"
              />
            </Text>
            <Text lineHeight="45px" fontSize="1.4rem" mt={8} mb={8}>
              Zealight is a technology company dedicated to building modern
              digital products that allows businesses in Africa & world at large
              better place.
            </Text>
            <Text mb={6} lineHeight="45px" fontSize="1.4rem">
              We have worked with businesses in the continent to understand
              their needs, and we are always ready to deliver solutions that
              will enable businesses expand into international markets. Our team
              is composed of professionals with years experience in the
              industry.
            </Text>
          </Box>
        </Box>
        <Box
          data-aos="fade-left"
          backgroundImage={Man}
          backgroundSize="cover"
          backgroundPosition="center"
          flex="1"
        ></Box>
      </Stack>
    </Box>
  );
};

export default Who;
