import { Box, Button, Heading, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import BlogCards from "./blogCards/blogCards";

import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen";
import { baseUrl } from "./AllPosts";

const Blog = () => {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch(baseUrl)
      .then((data) => data?.json())
      .then((json) => {
        setLoading(false);
        setPosts(json?.blog?.data?.blog);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return (
    <Box
      data-aos="fade-up"
      // marginTop="100px"
      marginBottom="80px"
      padding={["2rem 2rem", "2rem 6rem"]}
    >
      <Stack
        direction={["column", "row"]}
        marginTop="7rem"
        marginBottom="2rem"
        alignItems="center"
        justifyContent="space-between"
        gap="2rem"
      >
        <Box>
          <Heading
            marginLeft={["0px", "0px", "0px", "50px"]}
            lineHeight="40px"
            size="lg"
            className="bold"
            fontSize={["3.5em", "4em", "4em", "7rem"]}
            color="#290F44"
            opacity="0.1"
          >
            READ BLOG
          </Heading>
          {/* <Flex> */}
          <Heading
            className="bold"
            maxW="600px"
            fontWeight="600"
            fontSize="1.9rem"
            color="#290F44"
            textAlign={["center", "initial"]}
          >
            STAY INFORMED <br /> ON OUR LATEST NEWS{" "}
            <Box
              // ml="2px"
              display={"inline-block"}
              w="10px"
              h="10px"
              borderRadius="50%"
              bg="#00FE98"
            />
          </Heading>
          {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
          {/* </Flex> */}
        </Box>
        <Button
          mt={10}
          width={170}
          padding="1.5rem 3rem"
          borderRadius="5px"
          bg="#290F44"
          _hover={{ background: "#00FE98" }}
          color="#fff"
          fontSize="14px"
          onClick={() => navigate("/blog")}
          boxShadow={"13px 20px 20px 0px #290f443b;"}
        >
          VIEW ALL POSTS
        </Button>
      </Stack>
      {loading ? (
        <LoadingScreen justLoader />
      ) : (
        <BlogCards blogs={posts?.slice(0, 6)} />
      )}
    </Box>
  );
};

export default Blog;
