import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContactBanner from "../../landing/components/contactBanner/contactbanner";
import ProductDetailsTemplate from "./products/ProductDetailsTemplate";
import myEsate1 from "../../../assets/myestate-1.png";
import myEsate2 from "../../../assets/myestate-2.png";
import myEsate3 from "../../../assets/myestate-3.png";
import skillupRadio1 from "../../../assets/skill-radio-1.png";
import skillupRadio2 from "../../../assets/skill-radio-2.png";
import skedu1 from "../../../assets/skedu-1.png";
import skedu2 from "../../../assets/skedu-2.png";
import skedu3 from "../../../assets/skedu-3.png";
import fixmeet1 from "../../../assets/fixmeet-1.png";
import fixmeet2 from "../../../assets/fixmeet-2.png";
import fixmeet3 from "../../../assets/fixmeet-3.png";
import wehaul1 from "../../../assets/wehaul-1.png";
import wehaul2 from "../../../assets/wehaul-2.png";
import wehaul3 from "../../../assets/wehaul-3.png";
import lms1 from "../../../assets/lms-1.png";
import lms2 from "../../../assets/lms-2.png";
import lms3 from "../../../assets/lms-3.png";

export const PRODUCT_DESCRIPTION = [
  {
    id: 1,
    brandName: "MyEstate",
    data: [
      {
        imageDetails: {
          moveImgRight: true,
          image: myEsate1,
        },
        title: "Improved Gated Community Interactions",
        description:
          "All in one gated estate management system to make your estate run efficiently.",
      },
      {
        imageDetails: {
          moveImgRight: false,
          image: myEsate2,
        },
        title: "Send Errands",
        description:
          "Use our errand runners to get more done they are always available to carry out delivery and errand tasks on time and effectively",
      },
      {
        imageDetails: {
          moveImgRight: true,
          image: myEsate3,
        },
        title: "Mobile App For Residents",
        description:
          "All in one intuitive app, give your residents the best estate experience with the estate app.",
        backgroundColor: "#F0EDF5",
      },
    ],
  },
  {
    id: 2,
    brandName: "Skedu",
    data: [
      {
        imageDetails: {
          moveImgRight: true,
          image: skedu1,
        },
        title: "Visitor Management System",
        description:
          "Skedu is a web based visitor management system with SAAS built to help companies and organizations manage record of visitors and control access into the premises",
      },
      {
        imageDetails: {
          moveImgRight: false,
          image: skedu2,
          waterMark: "Managing System",
        },
        title: "The Managing System",
        description:
          "The software completely manages all the activities surrounding guests with features like Online and Offline meeting scheduling, guest id, appointment booking, package delivery and so much more",
      },
      {
        imageDetails: {
          moveImgRight: true,
          image: skedu3,
        },
        title: "Control access into your organisation",
        description: "Define who can access your space and the time they do so",
      },
    ],
  },
  {
    id: 3,
    brandName: "Fixmeet",
    data: [
      {
        imageDetails: {
          moveImgRight: true,
          image: fixmeet1,
        },
        title: "Meetings Made Simple.",
        description:
          "Fix your meeting date and time based on all participants’ availability and share the final decision easily via email with all participants with your custom message",
      },
      {
        imageDetails: {
          moveImgRight: false,
          image: fixmeet2,
          waterMark: "Easy Polling",
        },
        title: "Easy Polling",
        description:
          "Fix a range for your best days and times your meeting can hold to allow all participants respond with their availability.",
      },
      {
        imageDetails: {
          moveImgRight: true,
          image: fixmeet3,
          waterMark: "Share",
        },
        title: "Share Everywhere",
        description:
          "With Fixmeet, you can share your meeting polls via email, social media or any platform of your choice with easy",
      },
    ],
  },
  {
    id: 4,
    brandName: "WeHaul",
    data: [
      {
        imageDetails: {
          moveImgRight: true,
          image: wehaul1,
        },
        title: "Your One-stop logistics Company",
        description:
          "A logistics platform for connecting you with the best transportation medium and driver",
      },
      {
        imageDetails: {
          moveImgRight: false,
          image: wehaul2,
          waterMark: "Insurance",
        },
        title: "Insurance",
        description:
          "You get insurance for all your goods being moved via the Wehaul Logistics platform",
      },
      {
        imageDetails: {
          moveImgRight: true,
          image: wehaul3,
          waterMark: "Tracking",
        },
        title: "Live Tracking",
        description:
          "Track your goods from order to delivery from your device real time wherever you are",
      },
    ],
  },
  {
    id: 5,
    brandName: "Skillup Radio",
    data: [
      {
        imageDetails: {
          moveImgRight: true,
          image: skillupRadio1,
        },
        title: "Promoting Technology in Africa",
        description:
          "A logistics platform for connecting you with the best transportation medium and driver",
      },
      {
        imageDetails: {
          moveImgRight: false,
          image: skillupRadio2,
          waterMark: "Connect",
        },
        title: "Insurance",
        description:
          "Skillup Africa Radio app helps you connect 247 to tech news, events, programs and many more updates from your mobile phone",
      },
    ],
  },
  {
    id: 6,
    brandName: "Zil-LMS",
    data: [
      {
        imageDetails: {
          moveImgRight: true,
          image: lms1,
        },
        title: "Zealight learning management system",
        description:
          "Delivering the right learning content to the right employees at the right time will result in a superior learning experience.",
      },
      {
        imageDetails: {
          moveImgRight: false,
          image: lms2,
          waterMark: "Production Means",
        },
        title: "Zealight learning management system",
        description:
          "Delivering the right learning content to the right employees at the right time will result in a superior learning experience.",
      },
      {
        imageDetails: {
          moveImgRight: true,
          image: lms3,
          waterMark: "Production Means",
        },
        title: "Zealight learning management system",
        description:
          "Delivering the right learning content to the right employees at the right time will result in a superior learning experience.",
      },
    ],
  },
];

function ProductDetails() {
  let { id } = useParams();
  const [infoViewData, setInfoViewData] = useState(null);

  useEffect(() => {
    if (!id) return;
    const productInfo = PRODUCT_DESCRIPTION.find(
      (product) => product?.id === parseInt(id)
    );
    if (!productInfo) {
      setInfoViewData(null);
      return;
    }
    setInfoViewData(productInfo);
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      data-aos="fade-up"
      padding={["10rem 1rem 0 1rem", "10rem 6rem 0 6rem"]}
      bg="#fefffe"
    >
      <ProductDetailsTemplate product={infoViewData} />

      <Box mx="-10rem">
        <ContactBanner mb="0" />
      </Box>
    </Box>
  );
}

export default ProductDetails;
