import view2 from "./assets/view2.png";
import howbodi_landing from "./assets/howbodi-landing.png";
import { SingleProductPage } from "./components/SingleProductPage";
import docMother from "./assets/doc-mother.png";
import { useEffect } from "react";

const Howbodi = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SingleProductPage
        product="How bodi"
        bannerBg="#F3FEFF"
        purpose="Take Care Of Your Mental Health"
        projectClient="Pinnacle medical service"
        view={view2}
        aboutProject="Howbodi is a platform that helps check your mental state and proffer possible solutions to a variety of mental issues. Howbodi features on easy to use mental health assessment, an instant report generation, and mental health consultation bookings."
        product_landing_page={howbodi_landing}
        landingBG="#7baeb3ff"
        aboutImage={docMother}
      />
    </>
  );
};

export default Howbodi;
