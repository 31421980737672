import Aos from "aos";
import { useEffect } from "react";
import "./App.css";
import "aos/dist/aos.css";
import Navbar from "./components/navbar/navbar";
import Landing from "./pages/landing/landing";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/footer/footer";
import About from "./pages/about/about";
import Services from "./pages/services/services";
import Products from "./pages/products/products";
import Portfolio from "./pages/portfolio/portfolio";
import Contact from "./pages/contact/contact";
import Faq from "./pages/faqs/faq";
import ViewBlogDetails from "./pages/blog/ViewBlogDetails";
import Teams from "./pages/teams";
import ProductDetails from "./pages/products/components/ProductDetails";
import AllPosts from "./pages/blog/AllPosts";
import ProductDevelopment from "./pages/services/components/servicesContents/ProductDevelopment";
import UxResearch from "./pages/services/components/servicesContents/UxResearch";
import ItConsultancy from "./pages/services/components/servicesContents/ItConsultancy";
import TalentOutsourcing from "./pages/services/components/servicesContents/TalentOutsourcing";
import QualityAssurance from "./pages/services/components/servicesContents/QualityAssurance";
import MobileAppDevelopment from "./pages/services/components/servicesContents/MobileAppDevelopment";
import Howbodi from "./pages/howbodi";
import Constrack from "./pages/constrack";
import Jojolo from "./pages/jojolo";
import Metamophorsis from "./pages/metamorphosis";
import NailKeeper from "./pages/nail keeper";
import OKR from "./pages/okr";
import RentIt from "./pages/rent it";
import WMS from "./pages/wms";
import LoadingScreen from "./components/LoadingScreen";
import { useState } from "react";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    let timeout;
    timeout = setTimeout(() => {
      setLoading(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

  return loading ? (
    <LoadingScreen />
  ) : (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route
          path="/service/product-development"
          element={<ProductDevelopment />}
        />
        <Route path="/service/ux-research" element={<UxResearch />} />
        <Route path="/service/it-consultancy" element={<ItConsultancy />} />
        <Route
          path="/service/talent-outsourcing"
          element={<TalentOutsourcing />}
        />
        <Route
          path="/service/quality-assurance"
          element={<QualityAssurance />}
        />
        <Route
          path="/service/mobile-app-development"
          element={<MobileAppDevelopment />}
        />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:id" element={<ProductDetails />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio/howbodi" element={<Howbodi />} />
        <Route path="/portfolio/constrack" element={<Constrack />} />
        <Route path="/portfolio/jojolo" element={<Jojolo />} />
        <Route path="/portfolio/metamophorsis" element={<Metamophorsis />} />
        <Route path="/portfolio/nail-keeper" element={<NailKeeper />} />
        <Route path="/portfolio/okr" element={<OKR />} />
        <Route path="/portfolio/rent-it" element={<RentIt />} />
        <Route path="/portfolio/wms" element={<WMS />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/blog" exact element={<AllPosts />} />
        <Route path="/blog/:id" exact element={<ViewBlogDetails />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
