import { Box, Divider, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { NAV_LINKS } from "../navbar";

function MobileNav() {
  const [showNav, setShowNav] = useState(false);
  return (
    <Box pr="20px">
      <GiHamburgerMenu
        cursor={"pointer"}
        onClick={() => setShowNav((prev) => !prev)}
        fontSize={"1.5em"}
      />
      {
        <Box
          position={"absolute"}
          right={showNav ? "-10px" : "-1000px"}
          left={showNav ? "0px" : ""}
          w="100vw"
        >
          <Stack alignItems="center" bg="#e4f3f6" py="10px">
            {NAV_LINKS.map((navLink) => {
              return (
                <>
                  <Link to={navLink.link} onClick={() => setShowNav(false)}>
                    <Text fontWeight="600" color="#100120" p="10px">
                      {navLink.title}
                    </Text>
                  </Link>
                  <Divider />
                </>
              );
            })}
          </Stack>
        </Box>
      }
    </Box>
  );
}

export default MobileNav;
