import { Box, Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import ContactBanner from "../../../landing/components/contactBanner/contactbanner";
import ListBoxes from "../ListBoxes";
import ServiceDetailHeader from "../ServiceDetailHeader";
import TextOverlayOnImage from "../TextOverlayOnImage";
import itConsultancy1 from "../../../../assets/it-consultancy-1.png";

function ItConsultancy() {
  const lists = [
    {
      title: "Problem",
      description: " Identify clients problem that needs to be solved",
    },
    {
      title: "Audit",
      description: "Perform technical audit to decipher solution for client",
    },
    {
      title: "Report",
      description:
        "Prepare report recommending solution to the initial problem",
    },
    {
      title: "Follow Up",
      description:
        "Follow up to make sure recommendations were properly implemented",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box data-aos="fade-up" bg="#fefffe">
      <ServiceDetailHeader
        subHeading={"I.T Consultancy"}
        heading={
          "RECEIVE RECOMMENDATIONS ABOUT TECHNOLOGY STACKS AND IMPLEMENTATION PROCESSES"
        }
      />
      <TextOverlayOnImage
        img={itConsultancy1}
        title="WE PRIDE OURSELVES AS THE BEST IN I.T CONSULTING"
        containerColor={"#f7f7f7e6"}
        desc="Our mission is to analyze and solve the strategic or routine IT problems our customers have. We feel strongly that the core of our business is the provision of IT consulting services which is implemented by combination of talented information technology consultants, practical methodologies, and a passion for delivering truly beneficial IT solutions."
      />
      <Stack position="relative" mb="50px">
        <Text
          fontFamily={"Trenda-heavy"}
          fontSize={["3em", "6em"]}
          color="#e9e7ec"
          ml="80px"
        >
          WHAT
        </Text>
        <Text
          color="#100120"
          fontFamily={"Trenda-bold"}
          position="absolute"
          bottom="-15px"
          maxWidth="500px"
          marginLeft="50px !important"
          fontSize={"1.5em"}
        >
          WHAT WE DO
        </Text>
      </Stack>
      <ListBoxes lists={lists} />
      <ContactBanner />
    </Box>
  );
}

export default ItConsultancy;
