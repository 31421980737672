import React, { useEffect } from "react";
import { SingleProductPage } from "../howbodi/components/SingleProductPage";
import meta from "./assets/meta-morph.png";
import universityStd from "./assets/univer-std.png";
import lms_landing from "./assets/meta-landing-page.png";

const Metamophorsis = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SingleProductPage
        product="Metamorphosis University"
        bannerBg="#A100A217"
        purpose="Metamorphosis University"
        projectClient="DDk Innovation"
        view={meta}
        pr={"6rem"}
        aboutProject="Metamorphosis University is an ed-tech learning management system providing transforming teaching and tools that empower visionaries and legacy leaders. The platform is built for one of the biggest coaches in Nigeria with expected learner’s range of 100,000 - 500,000 users, housing over 200 micro-courses and 40+ learning programs.This product was developed with both a web and mobile application for its learners in over 123 countries."
        product_landing_page={lms_landing}
        landingBG="#224869ff"
        aboutImage={universityStd}
      />
    </>
  );
};

export default Metamophorsis;
