import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Img,
  Stack,
  Text,
} from "@chakra-ui/react";
import One from "../../assets/home/one.svg";
import Two from "../../assets/home/two.png";
import Three from "./home-4/one.png";
import Four from "./home-4/two.png";
import Five from "./home-4/three.png";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const navigate = useNavigate();
  return (
    <Box data-aos="fade-up" padding={["0 2rem", "0 2rem", "0 2rem", "0 6rem"]}>
      <Box>
        <Heading
          marginLeft="60px"
          lineHeight="40px"
          size="lg"
          className="bold"
          fontSize={["2.5em", "7rem"]}
          color="#290F44"
          opacity="0.1"
        >
          PRODUCTS
        </Heading>
        {/* <Flex> */}
        <Heading
          className="bold"
          maxW="850px"
          fontWeight="600"
          fontSize={["1.5em", "1.9rem"]}
          color="#290F44"
          textAlign={["center", "initial"]}
        >
          BUILDING PRODUCTS THAT SOLVE HUMAN PROBLEMS IN THE MOST OPTIMAL WAYS{" "}
          <Box
            // ml="2px"
            display={"inline-block"}
            w="10px"
            h="10px"
            borderRadius="50%"
            bg="#00FE98"
          />
        </Heading>
        {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
        {/* </Flex> */}
      </Box>

      <Stack
        direction={["column", "row"]}
        justifyContent="space-between"
        gap="2rem"
        marginTop="5rem"
      >
        <Box flex="1" data-aos="fade-right">
          <Box
            overflow="hidden"
            padding="3rem 0 0 2rem"
            boxShadow="0px 3px 6px #00000012"
            flex="1"
            position="relative"
            bg="#ECEFF9"
            backgroundSize="cover"
            height={["450px", "600px"]}
            maxWidth="560px"
            borderRadius="5px"
            onClick={() => navigate("/products/3")}
            cursor="pointer"
          >
            <Box className="overlay" borderRadius="15px"></Box>
            <Img loading="lazy" width={["100px", "35%"]} src={Two} />
            <Text
              lineHeight="26px"
              mt={7}
              color="#100120"
              fontSize="1.2rem"
              maxW={300}
            >
              Save time choosing when to meet in groups
            </Text>

            <Img loading="lazy" position="absolute" src={Three} />

            <Box position="absolute" bottom="3%" left="5%">
              <Box>
                <IconButton
                  _hover={{ background: "#00FE98" }}
                  colorScheme="purple"
                  aria-label="Search database"
                  height={7}
                  icon={<ArrowForwardIcon />}
                  onClick={() => navigate("/products/3")}
                />
              </Box>
            </Box>
          </Box>
          <Text
            fontSize="1.2rem"
            mt={6}
            // textDecoration="underline"
            onClick={() => navigate("/products/3")}
            cursor="pointer"
          >
            Web Application
          </Text>
          <Heading
            mt={3}
            className="bold"
            letterSpacing="1.39px"
            maxW="850px"
            fontWeight="600"
            fontSize="1.7rem"
            color="#290F44"
          >
            FIXMEET
          </Heading>
        </Box>
        <Box flex="1" data-aos="fade-left">
          <Box
            overflow="hidden"
            padding="3rem 0 0 2rem"
            boxShadow="0px 3px 6px #00000012"
            flex="1"
            position="relative"
            bg="#F8F0EF"
            backgroundSize="cover"
            height={["450px", "600px"]}
            maxWidth="560px"
            borderRadius="5px"
            onClick={() => navigate("/products/1")}
            cursor="pointer"
          >
            <Box className="overlay" borderRadius="15px"></Box>
            <Img loading="lazy" width="35%" src={One} />
            <Text
              mb="27px"
              lineHeight="26px"
              mt={7}
              color="#100120"
              fontSize="1.2rem"
              maxW={300}
            >
              Estate management software
            </Text>

            <Box>
              <Img loading="lazy" position="absolute" src={Four} />
              <Img
                loading="lazy"
                position="absolute"
                width={["100px", "40%"]}
                left="40%"
                bottom="7%"
                src={Five}
              />
            </Box>

            <Box position="absolute" bottom="3%" left="5%">
              <Box>
                <IconButton
                  _hover={{ background: "#00FE98" }}
                  colorScheme="purple"
                  aria-label="Search database"
                  height={7}
                  icon={<ArrowForwardIcon />}
                  onClick={() => navigate("/products/1")}
                />
              </Box>
            </Box>
          </Box>
          <Text
            fontSize="1.2rem"
            mt={6}
            // textDecoration="underline"
            onClick={() => navigate("/products/1")}
            cursor="pointer"
          >
            Web Application / Mobile Application
          </Text>
          <Heading
            mt={3}
            className="bold"
            letterSpacing="1.39px"
            maxW="850px"
            fontWeight="600"
            fontSize="1.7rem"
            color="#290F44"
          >
            MY ESTATE
          </Heading>
        </Box>
      </Stack>
      <Flex justifyContent="center">
        <Button
          margin="4rem auto"
          width={170}
          padding="1.5rem 3rem"
          borderRadius="5px"
          bg="#290F44"
          _hover={{ background: "#00FE98 " }}
          color="#fff"
          fontSize="14px"
          onClick={() => navigate("/products")}
          boxShadow={"13px 20px 20px 0px #290f443b;"}
        >
          VIEW ALL PRODUCTS
        </Button>
      </Flex>
    </Box>
  );
};

export default Products;
