import { Box, Text } from "@chakra-ui/react";
import Bg from "./NoPath/bg.png";

const CollabBanner = () => {
  return (
    <Box backgroundImage={Bg} backgroundSize="cover" padding="13rem 0">
      <Box
        data-aos="zoom-in"
        maxWidth={["90%", "70%"]}
        margin="0 auto"
        bg="#fff"
        padding={["4rem 2rem", "4rem 3rem"]}
        textAlign="center"
      >
        <Text
          mb={5}
          fontSize={["2rem", "3.5rem"]}
          color="#290F44"
          className="bold"
        >
          Its a collaboration
        </Text>
        <Text lineHeight="45px" fontSize="1.4rem" color="#290F44">
          Our goal is a full partnership. Prior to developing a single line of
          code, we examine problems, business models, and objectives. This
          guarantees that the best solution will be produced on our route to
          success.
        </Text>
      </Box>
    </Box>
  );
};

export default CollabBanner;
