import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import ContactBanner from "../landing/components/contactBanner/contactbanner";
import Journey from "./components/journey/journey";
import Overview from "./components/overview";
import Team from "./components/team/team";
import Vision from "./components/vision/vision";
import Who from "./components/who/who";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <Overview />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Who />
      <Journey />
      <br />
      <br />
      <br />
      <br />
      <Vision />
      <Team />
      <br />
      <br />
      <br />
      <br />
      <ContactBanner />
    </Box>
  );
};

export default About;
