import { Box, Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import ContactBanner from "../../../landing/components/contactBanner/contactbanner";
import ProductProcessList from "../ProductProcessList";
import ServiceDetailHeader from "../ServiceDetailHeader";
import TextOverlayOnImage from "../TextOverlayOnImage";
import uxReasearch1 from "../../../../assets/ux-research-1.png";

function UxResearch() {
  const processLists = [
    {
      title: "Research + Strategy",
      subTitle: "Before the action starts",
      processes: [
        "Flow Charts ",
        "Usability Tests",
        "Miro Mind Maps",
        "Design Thinking",
        "Empathy Map",
        "Design Sprints",
        " User Research",
        "Usability Audits",
        "User Personas",
        "User Journey Maps",
      ],
    },
    {
      title: "Wireframing + Prototyping",
      subTitle: "Creating the platform's structure",
      processes: [
        " Iteration ",
        " Mockup",
        "User Scenario",
        "Interaction Design",
        "Sketching",
        "Fidelity",
        " Micro-copy",
        "Responsive Web Design",
        " Call to Action",
        " Information Architecture",
      ],
    },
    {
      title: "Testing +Iteration",
      subTitle: "Making sure looks good",
      processes: [
        "Usability Tests",
        "User Interviews ",
        "Pain Points ",
        "User Research",
        "Accessibility",
        "Interaction Design",
        "Navigation",
        "Responsive Design",
        " End Users",
        "Card Sorting",
      ],
    },
  ];
  const uiDesignProcessList = [
    {
      processes: ["  White Space", "Icon", "Color Palettes"],
    },
    {
      processes: ["Typography", "Imagery", "Flat Design"],
    },
    {
      processes: ["Mood Board", "Style Guide", "Components"],
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box data-aos="fade-up" bg="#fefffe">
      <ServiceDetailHeader
        subHeading={"UX Research"}
        heading={
          "WE GATHER AND INTERPRET DATA FROM POTENTIAL USERS TO MAKE OUR DESIGNS 100% USER FRIENDLY"
        }
      />{" "}
      <TextOverlayOnImage
        img={uxReasearch1}
        title="WE FOCUS ON GATHERING DATA THAT ENHANCE USER EXPERIENCE"
        containerColor={"#f7f7f7e8"}
        desc="Our mission is to analyze and solve the strategic or routine IT problems our customers have. We feel strongly that the core of our business is the provision of IT consulting services which is implemented by combination of talented information technology consultants, practical methodologies, and a passion for delivering truly beneficial IT solutions."
      />
      <Stack position="relative" mb="70px">
        <Text
          fontFamily={"Trenda-heavy"}
          fontSize={["3em", "6em"]}
          color="#e9e7ec"
          ml="80px"
        >
          PROCESSES
        </Text>
        <Text
          color="#100120"
          fontFamily={"Trenda-bold"}
          position="absolute"
          bottom="-15px"
          maxWidth="500px"
          marginLeft="50px !important"
          fontSize={"1.5em"}
        >
          ALL OUR PRODUCT PROCESS STARTING FROM RESEARCH
        </Text>
      </Stack>
      <ProductProcessList lists={processLists} />
      <Stack
        justifyContent={"center"}
        alignItems="center"
        spacing={"-2px"}
        mb="50px"
      >
        <Text fontFamily={"Trenda-bold"} fontSize="2em">
          UI Design
        </Text>
        <Text>Making the app/website visually appealing</Text>
      </Stack>
      <ProductProcessList lists={uiDesignProcessList} />
      <ContactBanner />
    </Box>
  );
}

export default UxResearch;
