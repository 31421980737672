import React from "react";
import { SingleProductPage } from "../howbodi/components/SingleProductPage";
import rentIt from "./assets/rent-it.png";
import about_rent_it from "./assets/about-rent-it.png";
import rentland from "./assets/screenshot.png";

const RentIt = () => {
  return (
    <>
      <SingleProductPage
        product="Rentit"
        bannerBg="#FCF8F5D9"
        purpose="Find Anything You Need To Rent."
        projectClient="Rentit"
        view={rentIt}
        pr={"6rem"}
        aboutProject="Rentit is an online marketplace platform that makes it easy for you to rent anything ranging from luxury vehicles, coasters and Hiace buses, trucks and vans for moving things, generators, air conditioners, party items, wedding gowns, suits, construction equipment, cleaning equipment, and tech products such as cameras, projectors, screens, and drones. Rentit was built as a web only service."
        aboutImage={about_rent_it}
        product_landing_page={rentland}
        landingBG="#224869ff"
      />
    </>
  );
};

export default RentIt;
