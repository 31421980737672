import React, { useEffect } from "react";
import { SingleProductPage } from "../howbodi/components/SingleProductPage";
import phone from "./assets/phone.png";
import mobile from "./assets/contmobile.png";
import landingMobile from "./assets/landing-phone.png";

const Constrack = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SingleProductPage
        product="Constrack"
        bannerBg="#E4F4FF"
        purpose="Track And Monitor Government Projects"
        projectClient="USAID under their SACE project"
        view={phone}
        pr={"6rem"}
        aboutProject="Cons Track is a mobile App that allows citizens in the comfort of their neighborhoods and communities to interrogate constituency projects using verified and validated information as well as regular updates on implementation. Users can view projects per state and community, elected representatives and project updates.This app was built for USAID under their SACE project."
        aboutImage={mobile}
        product_landing_page={landingMobile}
        landingBG="#224869ff"
      />
    </>
  );
};

export default Constrack;
