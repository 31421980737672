import {
  Box,
  Flex,
  HStack,
  Img,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import Logo from "./logolight.png";
import One from "./one.svg";
import Two from "./two.svg";
import Three from "./three.svg";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box padding={["4rem 4rem 2rem 4rem", "6rem 6rem 2rem 6rem"]} bg="#290F44">
      <Stack direction={["column", "row"]}>
        <Box flex="1">
          <Img loading="lazy" mb={5} src={Logo} width="50%" />
          <Text maxW="200px" lineHeight="24px" color="#fff" opacity=".7">
            We have the zeal to make and build it better
          </Text>
        </Box>
        <Box flex="1">
          <Flex justifyContent="space-around">
            <UnorderedList color="#fff" lineHeight="36px" listStyleType="none">
              <ListItem fontSize="1.2rem" color="#BC88F3" mb={4}>
                Company
              </ListItem>
              <ListItem
                cursor="pointer"
                transition="250ms ease"
                _hover={{ color: "#BC88F3" }}
                onClick={() => navigate("/about")}
              >
                About Us
              </ListItem>
              <ListItem
                cursor="pointer"
                transition="250ms ease"
                _hover={{ color: "#BC88F3" }}
                onClick={() => navigate("/services")}
              >
                Services
              </ListItem>
              <ListItem
                cursor="pointer"
                transition="250ms ease"
                _hover={{ color: "#BC88F3" }}
                onClick={() => navigate("/portfolio")}
              >
                Portfolio
              </ListItem>
              <ListItem
                cursor="pointer"
                transition="250ms ease"
                _hover={{ color: "#BC88F3" }}
                onClick={() => navigate("/products")}
              >
                Products
              </ListItem>
              <ListItem
                cursor="pointer"
                transition="250ms ease"
                _hover={{ color: "#BC88F3" }}
                onClick={() => navigate("/blog")}
              >
                Blog
              </ListItem>
            </UnorderedList>
            <Box>
              <UnorderedList
                color="#fff"
                lineHeight="36px"
                listStyleType="none"
              >
                <ListItem
                  color="#BC88F3"
                  fontSize="1.2rem"
                  mb={4}
                  onClick={() => navigate("/contact")}
                >
                  Contact Us
                </ListItem>
                <Link to="/faqs">
                  <ListItem
                    cursor="pointer"
                    transition="250ms ease"
                    _hover={{ color: "#BC88F3" }}
                    onClick={() => navigate("/faqs")}
                  >
                    FAQs
                  </ListItem>
                </Link>
              </UnorderedList>
              <Box marginLeft="12px" marginTop="50px">
                <Text mb={2} color="#CFAAF6" className="bold" fontSize=".9rem">
                  Feel free to get in touch with us
                </Text>
                <a
                  href="mailto:zealightlabs@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text color="#fff">Zealightlabs@gmail.com</Text>
                </a>
                <HStack mt={5} gap="2rem" alignItems="center">
                  <Img
                    loading="lazy"
                    cursor="pointer"
                    transition="250ms ease"
                    _hover={{ transform: "scale(.8)" }}
                    src={One}
                  />
                  <Img
                    loading="lazy"
                    cursor="pointer"
                    transition="250ms ease"
                    _hover={{ transform: "scale(.8)" }}
                    transform="translateY(4px)"
                    src={Three}
                  />
                  <Img
                    loading="lazy"
                    cursor="pointer"
                    transition="250ms ease"
                    _hover={{ transform: "scale(.8)" }}
                    transform="translateY(4px)"
                    src={Two}
                  />
                </HStack>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Stack>
      <Text marginTop="50px" color="#fff">
        Copyright © 2021. All Rights Reserved.
      </Text>
    </Box>
  );
};

export default Footer;
