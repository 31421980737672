import { Box } from "@chakra-ui/react";

function Elipses({ farther }) {
  return (
    <Box position={"relative"}>
      <Box
        position={"absolute"}
        borderRadius={"50%"}
        top={farther ? "20px" : "80px"}
        // left={["50px", "300px", "300px", "1054px"]}
        right="250px"
        zIndex="999"
        width="70px"
        height="70px"
        transform="matrix(-0.93, -0.37, 0.37, -0.93, 0, 0)"
        /* UI Properties */
        background="transparent linear-gradient(181deg, #00FE981C 0%, #00fe9887 100%) 0% 0% no-repeat padding-box"
        opacity="1"
      />
      <Box
        position={"absolute"}
        borderRadius={"50%"}
        top="300px"
        left="100px"
        width="70px"
        height="70px"
        transform="matrix(1, -0.07, 0.07, 1, 0, 0)"
        /* UI Properties */
        background="transparent linear-gradient(181deg, #00FE981C 0%, #00fe9887 100%) 0% 0% no-repeat padding-box"
        opacity="1"
      />
    </Box>
  );
}

export default Elipses;
