import { Box, Heading, Img, SimpleGrid, Text } from "@chakra-ui/react";
import Elipses from "../../../components/Elipses";
import Map from "../assets/about/map.png";

const Overview = () => {
  return (
    <Box
      padding={["5.5rem 2rem 0 2rem", "5.5rem 5rem 0 5rem"]}
      height={["100%", "90vh"]}
      data-aos="fade-up"
    >
      <Elipses />
      <Box position="relative">
        <Text mb={5} marginTop="100px" color="#290F44" fontSize="1.6rem">
          ABOUT US
        </Text>
        <Heading
          className="bolder"
          maxW={["90%", "80%"]}
          fontSize={["2em", "4rem"]}
          lineHeight={["50px", "80px"]}
          color="#100120"
        >
          We believe that the next billion users deserve user-friendly software
          tools
          <Box
            display={"inline-block"}
            w="10px"
            h="10px"
            borderRadius="50%"
            bg="#00FE98"
          />
        </Heading>
        <Img loading="lazy" position="absolute" top="50%" src={Map} />
      </Box>
      {/* <HStack justifyContent="space-between"> */}
      <SimpleGrid
        columns={[2, 3, 3, 4]}
        spacing={["50px", "100px"]}
        marginTop="150px"
        justifyItems="flex-end"
        justifyContent={"space-around"}
        // display="none"
      >
        <Box>
          <Text className="bolder" fontSize="1.4rem" mb={["10px", "initial"]}>
            82%
          </Text>
          <Text className="bold" fontSize="1.2rem">
            We grow by 82% yearly
          </Text>
          <Text fontSize="1.1rem" maxW="300px">
            It has been an amazing journey but it's just getting started
          </Text>
        </Box>
        <Box>
          <Text className="bolder" fontSize="1.4rem" mb={["10px", "initial"]}>
            100%
          </Text>
          <Text className="bold" fontSize="1.2rem">
            Customer Satisfaction
          </Text>
          <Text fontSize="1.1rem" maxW="300px">
            Our goal is to create lasting relationships.
          </Text>
        </Box>
        <Box>
          <Text className="bolder" fontSize="1.4rem" mb={["10px", "initial"]}>
            500+
          </Text>
          <Text className="bold" fontSize="1.2rem">
            Client
          </Text>
          <Text fontSize="1.1rem" maxW="300px">
            Happy Client Around the World
          </Text>
        </Box>
        <Box>
          <Text className="bolder" fontSize="1.4rem" mb={["10px", "initial"]}>
            1000+
          </Text>
          <Text className="bold" fontSize="1.2rem">
            Projects
          </Text>
          <Text fontSize="1.1rem" maxW="300px">
            Completed project
          </Text>
        </Box>
      </SimpleGrid>
      {/* </HStack> */}
    </Box>
  );
};

export default Overview;
