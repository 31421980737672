import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Elipses from "../../components/Elipses";
import LoadingScreen from "../../components/LoadingScreen";
import BlogCards from "./blogCards/blogCards";

export const baseUrl = "https://zealight-website.herokuapp.com/blog/get-blog";

function AllPosts() {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch(baseUrl)
      .then((data) => data.json())
      .then((json) => {
        setLoading(false);
        setPosts(json?.blog?.data?.blog);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return (
    <Box
      data-aos="fade-up"
      marginBottom="80px"
      padding={["2rem 2rem", "5.5rem 6rem"]}
    >
      <Elipses />
      <Stack h="100vh">
        <Stack
          my="50px"
          p={["20px", "100px"]}
          justifyContent={"center"}
          alignItems="center"
        >
          <Stack direction={"row"} alignItems="flex-end">
            <Heading fontFamily={"Trenda-heavy"} fontSize="4em" color="#290F44">
              BLOG
            </Heading>
            <Box
              w="15px"
              h="15px"
              borderRadius={"50%"}
              bg="#00FE98"
              mb="20px !important"
            />
          </Stack>

          <Text maxW={"450px"} textAlign="center" fontSize={"1.2em"}>
            Sharing our thought and perspectives on the innovations and
            technologies we use
          </Text>
        </Stack>
      </Stack>

      {loading ? (
        <LoadingScreen justLoader />
      ) : (
        <BlogCards blogs={posts} vertical />
      )}
    </Box>
  );
}

export default AllPosts;
