import { Box, Flex, Heading, Img, Stack, Text } from "@chakra-ui/react";

import Hblogo from "../../assets/PortfolioAssets/hblogo.svg";
import Hb from "../../assets/PortfolioAssets/hb.png";
import Mulogo from "../../assets/PortfolioAssets/mulogo.svg";
import Mu from "../../assets/PortfolioAssets/mu.png";
import Nklogo from "../../assets/PortfolioAssets/nklogo.svg";
import Nk from "../../assets/PortfolioAssets/nk.png";
import Rtlogo from "../../assets/PortfolioAssets/rtlogo.png";
import Rt from "../../assets/PortfolioAssets/rt.png";
import Jologo from "../../assets/PortfolioAssets/JOJOLO.svg";
import Jo from "../../assets/PortfolioAssets/jo.png";
import Wmlogo from "../../assets/PortfolioAssets/wmlogo.svg";
import Wm from "../../assets/PortfolioAssets/wm.png";
import Oklogo from "../../assets/PortfolioAssets/OKR.svg";
import Cklogo from "../../assets/PortfolioAssets/CONSTRACK.svg";
import companyOKR from "../../assets/PortfolioAssets/companyOKR.jpg";
import constrackPhone from "../../assets/PortfolioAssets/constrackPhone.png";
import { useNavigate } from "react-router-dom";

const Productsx = () => {
  const navigate = useNavigate();

  return (
    <Box data-aos="fade-up" padding={["0 2rem", "0 6rem"]}>
      <Box>
        <Heading
          marginLeft="60px"
          lineHeight="40px"
          size="lg"
          className="bold"
          fontSize={["2rem", "5rem", "5rem", "7rem"]}
          color="#290F44"
          opacity="0.1"
        >
          PORTFOLIO
        </Heading>

        <Heading
          className="bold"
          maxW="850px"
          fontWeight="600"
          fontSize="1.9rem"
          color="#290F44"
          textAlign={["center", "left"]}
        >
          SOME EXTERNAL PROJECTS WE'VE BUILT
        </Heading>
      </Box>

      <Stack
        direction={["column", "row"]}
        justifyContent="space-between"
        gap="2rem"
        marginTop="5rem"
      >
        <Box flex="1" data-aos="fade-right">
          <Box
            cursor={"pointer"}
            onClick={() => navigate("/portfolio/howbodi")}
            overflow="hidden"
            padding="3rem 0 0 2rem"
            boxShadow="0px 3px 6px #00000012"
            flex="1"
            position="relative"
            bg="#EFFEFD"
            backgroundSize="cover"
            height={["400px", "600px"]}
            maxWidth="560px"
            borderRadius="5px"
          >
            <Box className="overlay" borderRadius="15px"></Box>
            <Img loading="lazy" width="35%" src={Hblogo} />
            <Text
              lineHeight="26px"
              mt={7}
              color="#100120"
              fontSize="1.2rem"
              maxW={300}
            >
              A platform to take care of your mental health
            </Text>

            <Img loading="lazy" position="absolute" src={Hb} />
          </Box>
        </Box>
        <Box flex="1" data-aos="fade-right">
          <Box
            cursor={"pointer"}
            onClick={() => navigate("/portfolio/metamophorsis")}
            overflow="hidden"
            padding="3rem 0 0 2rem"
            boxShadow="0px 3px 6px #00000012"
            flex="1"
            position="relative"
            bg="#F2E4FF8A"
            backgroundSize="cover"
            height={["400px", "600px"]}
            maxWidth="560px"
            borderRadius="5px"
          >
            <Box className="overlay" borderRadius="15px"></Box>
            <Img loading="lazy" width="35%" src={Mulogo} />
            <Text
              mb="27px"
              lineHeight="26px"
              mt={7}
              color="#100120"
              fontSize="1.2rem"
              maxW={300}
            >
              Providing mentorship sessions to students from all areas.
            </Text>

            <Img
              loading="lazy"
              width="85%"
              position="absolute"
              right="0%"
              src={Mu}
            />
          </Box>
        </Box>
      </Stack>
      <Stack
        direction={["column", "row"]}
        justifyContent="space-between"
        gap="2rem"
        marginTop="5rem"
      >
        <Box flex="1" data-aos="fade-right">
          <Box
            cursor={"pointer"}
            onClick={() => navigate("/portfolio/nail-keeper")}
            overflow="hidden"
            padding="3rem 0 0 2rem"
            boxShadow="0px 3px 6px #00000012"
            flex="1"
            position="relative"
            bg="#FFEDE2AB"
            backgroundSize="cover"
            height={["400px", "600px"]}
            maxWidth="560px"
            borderRadius="5px"
          >
            <Box className="overlay" borderRadius="15px"></Box>
            <Img loading="lazy" width="35%" src={Nklogo} />
            <Text
              lineHeight="26px"
              mt={7}
              color="#100120"
              fontSize="1.2rem"
              maxW={300}
            >
              A management system for one of Canada’s top nail salon brands.
            </Text>

            <Img loading="lazy" position="absolute" src={Nk} />
          </Box>
        </Box>
        <Box flex="1" data-aos="fade-right">
          <Box
            cursor={"pointer"}
            onClick={() => navigate("/portfolio/rent-it")}
            overflow="hidden"
            padding="3rem 0 0 2rem"
            boxShadow="0px 3px 6px #00000012"
            flex="1"
            position="relative"
            bg="#FFF5F7"
            backgroundSize="cover"
            height={["400px", "600px"]}
            maxWidth="560px"
            borderRadius="5px"
          >
            <Box className="overlay" borderRadius="15px"></Box>
            <Img loading="lazy" width="18%" src={Rtlogo} />
            <Text
              mb="27px"
              lineHeight="26px"
              mt={7}
              color="#100120"
              fontSize="1.2rem"
              maxW={500}
            >
              A platform for connecting individuals who need to move goods and
              products,
            </Text>
            <br />
            <Img
              loading="lazy"
              width="80%"
              right="0%"
              position="absolute"
              src={Rt}
            />
          </Box>
        </Box>
      </Stack>
      <Stack
        direction={["column", "row"]}
        justifyContent="space-between"
        gap="2rem"
        marginTop="5rem"
      >
        <Box flex="1" data-aos="fade-right">
          <Box
            cursor={"pointer"}
            onClick={() => navigate("/portfolio/jojolo")}
            overflow="hidden"
            padding="3rem 0 0 2rem"
            boxShadow="0px 3px 6px #00000012"
            flex="1"
            position="relative"
            bg="#F3FEFF"
            backgroundSize="cover"
            height={["400px", "600px"]}
            maxWidth="560px"
            borderRadius="5px"
          >
            <Box className="overlay" borderRadius="15px"></Box>
            <Img loading="lazy" width="30%" src={Jologo} />
            <Text
              lineHeight="26px"
              mt={7}
              color="#100120"
              fontSize="1.2rem"
              maxW={500}
            >
              Providing accessible means for mothers to engage healthcare
              professionals
            </Text>

            <Img loading="lazy" position="absolute" src={Jo} />
          </Box>
        </Box>
        <Box flex="1" data-aos="fade-right">
          <Box
            cursor={"pointer"}
            onClick={() => navigate("/portfolio/wms")}
            overflow="hidden"
            padding="3rem 0 0 2rem"
            boxShadow="0px 3px 6px #00000012"
            flex="1"
            position="relative"
            bg="#B3FFF666"
            backgroundSize="cover"
            height={["400px", "600px"]}
            maxWidth="560px"
            borderRadius="5px"
          >
            <Box className="overlay" borderRadius="15px"></Box>
            <Img loading="lazy" width="35%" src={Wmlogo} />
            <Text
              mb="27px"
              lineHeight="26px"
              mt={7}
              color="#100120"
              fontSize="1.2rem"
              maxW={400}
            >
              Providing easy vending and monitoring of household commodities
            </Text>

            <Box>
              <Img loading="lazy" position="absolute" src={Wm} />
            </Box>
          </Box>
        </Box>
      </Stack>

      <Stack
        direction={["column", "row"]}
        justifyContent="space-between"
        gap="2rem"
        marginTop="5rem"
      >
        <Box flex="1" data-aos="fade-right">
          <Box
            cursor={"pointer"}
            onClick={() => navigate("/portfolio/okr")}
            overflow="hidden"
            padding="2rem 0 0 2rem"
            boxShadow="0px 3px 6px #00000012"
            flex="1"
            position="relative"
            bg="#CECCFF61"
            backgroundSize="cover"
            height={["400px", "600px"]}
            maxWidth="560px"
            borderRadius="5px"
          >
            <Box className="overlay" borderRadius="15px"></Box>
            <Img loading="lazy" width="25%" src={Oklogo} />
            <Text
              lineHeight="26px"
              my={5}
              color="#100120"
              fontSize="1.2rem"
              maxW={500}
            >
              A management system built for one Lagos leading consulting firms.
            </Text>
            {/* /////// */}
            <Img loading="lazy" position="absolute" src={companyOKR} />
          </Box>
        </Box>
        <Box flex="1" data-aos="fade-right">
          <Box
            cursor={"pointer"}
            onClick={() => navigate("/portfolio/constrack")}
            overflow="hidden"
            padding="3rem 0 0 2rem"
            boxShadow="0px 3px 6px #00000012"
            flex="1"
            position="relative"
            bg="#B3FFF666"
            backgroundSize="cover"
            height={["400px", "600px"]}
            maxWidth="560px"
            borderRadius="5px"
          >
            <Box className="overlay" borderRadius="15px"></Box>
            <Img loading="lazy" width="35%" src={Cklogo} />
            <Text
              mb="27px"
              lineHeight="26px"
              mt={7}
              color="#100120"
              fontSize="1.2rem"
              maxW={400}
            >
              Providing easy vending and monitoring of household commodities
            </Text>

            <Flex bg="red" w="100%" justify={"center"}>
              <Img loading="lazy" position="absolute" src={constrackPhone} />
            </Flex>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Productsx;
