import {
  Box,
  Button,
  Flex,
  Heading,
  Img,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import "../../../../components/utilityBold.css";
import Gball from "../../assets/Ellipse 160/gball.png";
// import GridImage from "../../assets/Group 911/gp.png";
import GridImage from "../../../../assets/grid.png";
import One from "../../assets/home-3/one.svg";
import Two from "../../assets/home-3/two.svg";
import Three from "../../assets/home-3/three.svg";
import Four from "../../assets/home-3/four.svg";
import Five from "../../assets/home-3/five.svg";
import Six from "../../assets/home-3/six.svg";
import countUp from "../../../../assets/count-up.json";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";

const Why = () => {
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 600px)");
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: countUp,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box padding="10rem 6rem 4rem 6rem" bg="#290F44">
      <Stack
        direction={["column", "row"]}
        position="relative"
        marginBottom="2rem"
        alignItems="center"
        justifyContent="space-between"
        gap="2rem"
      >
        <Img
          loading="lazy"
          position="absolute"
          top="-100%"
          left="3%"
          src={Gball}
        />
        <Box>
          <Heading
            marginLeft="160px"
            lineHeight="40px"
            size="lg"
            className="bold"
            fontSize={["5em", "7rem"]}
            color="#FFF"
            opacity="0.22"
          >
            WHY
          </Heading>
          {/* <Flex> */}
          <Heading
            className="bold"
            maxW="400px"
            fontWeight="600"
            fontSize="1.9rem"
            color="#FFF"
            textAlign={["center", "initial"]}
          >
            WE ARE ONE OF THE BEST IN DIGITAL SOLUTIONS
            <Box
              ml="4px"
              display={"inline-block"}
              w="10px"
              h="10px"
              borderRadius="50%"
              bg="#00FE98"
            />
          </Heading>
          {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
          {/* </Flex> */}
        </Box>
        <Button
          mt={10}
          width={170}
          padding="1.5rem 3rem"
          borderRadius="5px"
          bg="#05C97A"
          _hover={{ background: "#05C97A" }}
          color="#fff"
          fontSize="14px"
          onClick={() => navigate("/services")}
          position="relative"
          zIndex={"66"}
        >
          VIEW OUR SERVICES
        </Button>
      </Stack>
      <Stack
        direction={["column", "row"]}
        marginTop="5rem"
        justifyContent="space-between"
        alignItems={"center"}
        gap={10}
      >
        <Box data-aos="fade-right" flex="1">
          <Flex flexWrap="wrap">
            <Box
              borderRight={["none", "1px solid gray"]}
              // borderBottom="1px solid gray"
              padding="4rem 0 2rem 0"
              textAlign="center"
              flex="1"
            >
              <Img loading="lazy" margin="0 auto" src={One} />
              <br />
              <Text maxW={170} margin="0 auto" color="#fff">
                Efficient Customer Support
              </Text>
            </Box>
            <Box
              padding="4rem 0 2rem 0"
              // borderBottom="1px solid gray"
              textAlign="center"
              flex="1"
            >
              <Img loading="lazy" margin="0 auto" src={Six} />
              <br />
              <Text maxW={170} margin="0 auto" color="#fff">
                Advanced Engineering
              </Text>
            </Box>
          </Flex>
          <Flex flexWrap="wrap">
            <Box
              borderRight={["none", "1px solid gray"]}
              // borderBottom="1px solid gray"
              padding="4rem 0 2rem 0"
              textAlign="center"
              flex="1"
            >
              <Img loading="lazy" margin="0 auto" src={Five} />
              <br />
              <Text maxW={170} margin="0 auto" color="#fff">
                Flexible Working Models
              </Text>
            </Box>
            <Box
              padding="4rem 0 2rem 0"
              // borderBottom="1px solid gray"
              textAlign="center"
              flex="1"
            >
              <Img loading="lazy" margin="0 auto" src={Two} />
              <br />
              <Text maxW={170} margin="0 auto" color="#fff">
                Structured Processes
              </Text>
            </Box>
          </Flex>
          <Flex flexWrap="wrap">
            <Box
              borderRight={["none", "1px solid gray"]}
              padding="4rem 0 2rem 0"
              textAlign="center"
              flex="1"
            >
              <Img loading="lazy" margin="0 auto" src={Four} />
              <br />
              <Text maxW={150} margin="0 auto" color="#fff">
                Comprehensive Research
              </Text>
            </Box>
            <Box padding="4rem 0 2rem 0" textAlign="center" flex="1">
              <Img loading="lazy" margin="0 auto" src={Three} />
              <br />
              <Text maxW={170} margin="0 auto" color="#fff">
                Experienced Development Team
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box data-aos="fade-left" flex="1">
          <Flex position={"relative"}>
            <Box
              px="20px"
              bg="#fff"
              position={"absolute"}
              zIndex="5"
              top={["90px", "-50px", "-50px", "80px"]}
              right={["-50px", "-30px", "-30px", "0"]}
              h={["85px", "110px"]}
              borderRadius={"6px"}
            >
              <Lottie
                options={defaultOptions}
                height={isMobile ? 60 : 70}
                width={isMobile ? 60 : 150}
                style={{ margin: "0 !important" }}
              />
              <Text fontWeight={"bold"} fontSize={[".7em", "1em"]}>
                I.T BUSINESS EXPERIENCE
              </Text>
            </Box>
            <Img
              loading="lazy"
              src={GridImage}
              transform={["scale(1.9)", "scale(1.5)"]}
              mt={["0", "-200px"]}
            />
          </Flex>
        </Box>
      </Stack>
    </Box>
  );
};

export default Why;
