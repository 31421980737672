import { Box, Heading, Img } from "@chakra-ui/react";
import Images from "./imgs.png";

const Clients = () => {
  return (
    <Box data-aos="fade-up" padding={["0 2rem", "0 6rem"]}>
      <Heading
        textAlign="center"
        className="bold"
        maxW="850px"
        margin="0 auto"
        fontWeight="600"
        fontSize={["1.5em", "1.9rem"]}
        color="#290F44"
      >
        WE PARTNER WITH AMBITIOUS CLIENTS TO DELIVER CHALLENGING TECHNOLOGY
        PROJECTS
      </Heading>
      <Img loading="lazy" mt={12} src={Images} />
    </Box>
  );
};

export default Clients;
