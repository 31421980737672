import { Box, Button, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import zilogo from "../../../../assets/zillightCodeLogo.svg";

const ContactBanner = () => {
  return (
    <Box
      data-aos="fade-right"
      bg="transparent linear-gradient(102deg, #E4F3F6 0%, #EDECFA 100%)"
    >
      <Flex justify="space-between" align="center" minH={["200px"]}>
        <Box alignSelf="flex-end">
          <Image loading="lazy" w="80%" src={zilogo} alt="logo" />
        </Box>
        <Flex
          flex="1"
          justify="space-between"
          direction={["column", "column", "column", "row"]}
        >
          <Stack>
            <Text fontSize={["1rem", "1.6rem"]}>Have a project?</Text>
            <Text fontSize={["1.2rem", "2rem"]} className="bold">
              Let's talk about it
            </Text>
          </Stack>

          <Link style={{ textDecoration: "none" }} to="/contact">
            <Button
              mt={10}
              width={170}
              padding="1.5rem 3rem"
              borderRadius="5px"
              bg="#290F44"
              _hover={{ background: "#00FE98" }}
              color="#fff"
              fontSize="14px"
              boxShadow={"13px 20px 20px 0px #290f443b;"}
            >
              CONTACT US
            </Button>
          </Link>
        </Flex>

        <Box alignSelf="flex-start" overflowY="hidden">
          <Image
            loading="lazy"
            pos="relative"
            top="-3rem"
            src={zilogo}
            alt="logo"
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default ContactBanner;
