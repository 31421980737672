import { Box, Heading, Img, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import allTeams from "../../assets/teams/all_teams.png";
import team1 from "../../assets/teams/1.png";
import team2 from "../../assets/teams/2.png";
import team3 from "../../assets/teams/3.jpeg";
import team4 from "../../assets/teams/4.png";
import team5 from "../../assets/teams/5.jpg";
import team6 from "../../assets/teams/6.png";
import team7 from "../../assets/teams/7.jpg";
import team8 from "../../assets/teams/8.png";
import team9 from "../../assets/teams/9.jpg";
import team10 from "../../assets/teams/10.png";
import team11 from "../../assets/teams/11.jpg";
import team12 from "../../assets/teams/12.png";
import team13 from "../../assets/teams/13.jpeg";
import team14 from "../../assets/teams/14.png";
import team15 from "../../assets/teams/15.png";
import team16 from "../../assets/teams/16.png";
import team17 from "../../assets/teams/17.jpeg";
import team18 from "../../assets/teams/18.jpeg";
import team19 from "../../assets/teams/19.png";
import team20 from "../../assets/teams/20.png";
import team21 from "../../assets/teams/21.jpg";
// import team22 from "../../assets/teams/22.png";
import team23 from "../../assets/teams/23.png";
import team24 from "../../assets/teams/24.png";
import { Parallax } from "react-parallax";

export const TEAMS_LIST = [
  { image: team1, name: "Temitayo Adejoke", role: "CEO" },
  { image: team2, name: "Jaabin Adekunle", role: "CMO" },
  { image: team23, name: "Gabriel Owoeye", role: "CTO" },
  { image: team3, name: "Joy", role: "HR" },
  { image: team4, name: "Naomi Tariah", role: "Lawyer" },
  {
    image: team16,
    name: "Jonathan",
    role: "Admin",
  },
  {
    image: team5,
    name: "Farouk Oyedeji",
    role: "Frontend Developer",
  },
  {
    image: team6,
    name: "Blessed Chukwu",
    role: "Backend Developer",
  },
  {
    image: team7,
    name: "Abisagbo Ayomide",
    role: "Product Designer",
  },
  {
    image: team8,
    name: "Adebayo Tobi",
    role: "Frontend Developer",
  },
  {
    image: team9,
    name: "Ghaniya",
    role: "QA/QC",
  },
  {
    image: team10,
    name: "Emmanuel Noah",
    role: "Backend Developer",
  },
  {
    image: team11,
    name: "Nnamdi",
    role: "QA/QC",
  },
  {
    image: team12,
    name: "Kayode",
    role: "QA/QC",
  },
  {
    image: team13,
    name: "Osaze Agbi",
    role: "Frontend Developer",
  },

  {
    image: team24,
    name: "Mayomi Jessica",
    role: "Product Designer (Team Lead)",
  },

  {
    image: team14,
    name: "Austin Onueze",
    role: "Frontend Developer",
  },
  {
    image: team15,
    name: "Emmanuel Ochade",
    role: "Frontend Developer (Team Lead)",
  },
  {
    image: team17,
    name: "Funmilayo Odutayo",
    role: " QA/QC (Team Lead)",
  },
  {
    image: team18,
    name: "Uche Egege.",
    role: "Mobile Developer (Team Lead)",
  },
  {
    image: team21,
    name: "Khosi",
    role: "Mobile Developer",
  },

  {
    image: team19,
    name: "Amakah Nwankwo",
    role: "Backend Developer",
  },
  {
    image: team20,
    name: "Adedolapo Adegoke",
    role: "Backend Developer (Team Lead)",
  },
];

function Teams() {
  const [currentMouseLocation, setCurrentMouseLocation] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      data-aos="fade-up"
      marginBottom="80px"
      padding={["0rem 2rem", "0rem 6rem"]}
    >
      <Parallax
        bgImage={allTeams}
        bgImageAlt="the cat"
        strength={600}
        renderLayer={() => (
          <div
            style={{
              position: "absolute",
              background: `#0000006b`,
              left: 0,
              top: 0,
              right: 0,
              width: "100%",
              height: "100%",
            }}
          />
        )}
        bgImageStyle={{ objectFit: "cover" }}
        style={{
          height: "100vh",
          textAlign: "center",
          marginLeft: "-6rem",
          marginRight: "-6rem",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Stack color="#fff">
          <Heading fontSize={"3em"} fontFamily="Trenda-bold">
            Our Team @
          </Heading>
          <Heading
            transition="all .2s ease-in-out"
            fontSize={["4em", "10em"]}
            textTransform={"uppercase"}
            fontFamily="Trenda-heavy"
            style={{
              "-webkit-text-stroke": "1px #fff",
              "-webkit-text-fill-color": "transparent",
            }}
            _hover={{
              // "-webkit-text-stroke": "1px #fff",
              "-webkit-text-fill-color": "#fff !important",
            }}
            //       " -webkit-text-stroke"= "1px black"
            // "-webkit-text-fill-color"="white"
          >
            Zealight
          </Heading>
          Zealight
        </Stack>
      </Parallax>

      <Stack mt="90px">
        <Text mb="20px">OUR AWESOME TEAM</Text>
        <Heading fontFamily={"Trenda-bold"} mb="30px" maxW="600px">
          Learn more about the people who make us awesome
        </Heading>
        <SimpleGrid columns={[2, 3, 3, 4]} spacing="40px" mt="60px !important">
          {TEAMS_LIST.map((team, id) => {
            return (
              <Box
                key={id}
                position={"relative"}
                onMouseOver={() => setCurrentMouseLocation(id)}
                onMouseOut={() => setCurrentMouseLocation(null)}
              >
                <Img
                  loading="lazy"
                  w="300px"
                  h="300px"
                  borderRadius={"20px"}
                  objectFit={"cover"}
                  src={team?.image}
                  alt={team?.role}
                  zIndex={currentMouseLocation === id ? 0 : 999}
                />
                <Stack
                  borderRadius={"20px"}
                  bg="#000000ba"
                  display={currentMouseLocation === id ? "flex" : "none"}
                  zIndex={currentMouseLocation === id ? 999 : 0}
                  position="absolute"
                  top="0"
                  left="0"
                  w="100%"
                  h="100%"
                  color="#fff"
                  justifyContent="flex-end"
                  alignItems="baseline"
                  p="10px"
                  fontSize={".8em"}
                >
                  <Text fontWeight={"bold"}>{team.name}</Text>
                  <Text maxW="120px">{team.role}</Text>
                </Stack>
              </Box>
            );
          })}
        </SimpleGrid>
      </Stack>
    </Box>
  );
}

export default Teams;
