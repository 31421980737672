import { useParams } from "react-router-dom";
import { BsFacebook } from "react-icons/bs";
import { AiFillLinkedin, AiOutlineTwitter } from "react-icons/ai";
import { useEffect, useState } from "react";
import { Box, Heading, Image, Stack, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { baseUrl } from "./AllPosts";
import LoadingScreen from "../../components/LoadingScreen";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function ViewBlogDetails() {
  let { id } = useParams();
  const [infoViewData, setInfoViewData] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    fetch(baseUrl)
      .then((data) => data.json())
      .then((json) => {
        let blogs = json?.blog?.data?.blog;

        const blogInfo = blogs.find(
          (blog) => blog?.title === id.split("_").join(" ")
        );
        if (!blogInfo) {
          setInfoViewData(null);
          return;
        }
        setInfoViewData(blogInfo);
      })
      .catch((e) => {
        // setLoading(false);
      });
  }, [id]);

  return infoViewData ? (
    <Box padding={["10rem 2rem", "10rem 6rem"]}>
      <Box data-aos="fade-up">
        <Text mb="10px">
          {" "}
          {dayjs(infoViewData?.createdAt).format("MMMM DD YYYY")}
        </Text>
        <Heading
          fontFamily={"Trenda-heavy"}
          fontSize={["2em", "2.5em"]}
          maxW="700px"
          textTransform={"uppercase"}
          mb="30px"
        >
          {infoViewData?.title}
        </Heading>
        <Stack
          direction={"row"}
          alignItems="flex-start"
          justifyContent={"flex-start"}
          spacing="30px"
          color="#080010"
        >
          <Text fontWight="bold">Written by: {infoViewData?.author}</Text>
          <Stack direction={"row"} alignItems="center">
            <Text>Share:</Text>
            <LinkedinShareButton url={window.location.href}>
              <AiFillLinkedin />
            </LinkedinShareButton>
            <TwitterShareButton url={window.location.href}>
              <AiOutlineTwitter />
            </TwitterShareButton>
            <FacebookShareButton url={window.location.href}>
              <BsFacebook />
            </FacebookShareButton>
          </Stack>
        </Stack>
        <Box>
          <Image
            loading="lazy"
            src={infoViewData?.image}
            width="100%"
            maxH="300px"
            objectFit={"cover"}
          />
        </Box>

        <Text maxW="700px" my="50px" lineHeight={2}>
          {infoViewData?.overview}
        </Text>
        {infoViewData?.subParagraphs &&
          infoViewData?.subParagraphs?.map((data, id) => {
            return (
              <Stack key={id} mb="20px" maxW="700px" spacing="30px">
                <Text fontFamily={"Trenda-bold"} fontSize="1.5em">
                  {data?.title}
                </Text>
                <Text lineHeight={2}>{data?.content}</Text>
              </Stack>
            );
          })}
        <Text fontWeight={"bold"} mt="70px">
          {" "}
          Tags: {infoViewData?.tags?.toLocaleString()}
        </Text>
      </Box>
    </Box>
  ) : (
    <Box h="400px">
      <LoadingScreen justLoader />
    </Box>
  );
}

export default ViewBlogDetails;
