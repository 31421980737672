import { SingleProductPage } from "../howbodi/components/SingleProductPage";
import jojoloBanner from "./assets/jojoloBanner.png";
import desktop from "./assets/desktop-mobile.png";

import family from "./assets/family.png";
import { useEffect } from "react";

function Jojolo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SingleProductPage
        product="Jojolo"
        bannerBg="#F3FEFF"
        purpose="Integrated medical telemedicine platform"
        projectClient="Neo Childcare NG"
        view={jojoloBanner}
        pr={"6rem"}
        aboutProject="Jojolo is an integrated telemedicine and booking platform where caregivers can find comfort accessing healthcare at their fingertips. The Jojolo platform would provide a convenient and accessible means for mothers to engage healthcare professionals from various disciplines, ask burning questions, get vital health tips, interact with one another and book Neo Childcare’"
        aboutImage={family}
        product_landing_page={desktop}
        landingBG="#224869ff"
      />
    </>
  );
}

export default Jojolo;
