import { Box, Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import ContactBanner from "../../../landing/components/contactBanner/contactbanner";
import ListBoxes from "../ListBoxes";
import ServiceDetailHeader from "../ServiceDetailHeader";
import TextOverlayOnImage from "../TextOverlayOnImage";
import qualityAssuranceOne from "../../../../assets/quality-assurance-1.png";

function QualityAssurance() {
  const lists = [
    {
      title: "Planning",
      description: "Plan and collate all test requirements",
    },
    {
      title: "Develop Test",
      description: "Setup the test environment and test cases",
    },

    {
      title: "Excution",
      description: "Carryout holistic testing by applying test cases.",
    },
    {
      title: "Reporting",
      description: "Prepare and present the test report",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box data-aos="fade-up" bg="#fefffe">
      <ServiceDetailHeader
        subHeading={"Quality Assurance"}
        heading={
          "WE PROVIDE TOP NOTCH QUALITY ASSURANCE SERVICES FOR OUR CLIENTS THROUGHOUT THE PROJECT DURATION"
        }
      />
      <TextOverlayOnImage
        img={qualityAssuranceOne}
        title="TEST PRODUCT QUALITY FOR OPTIMAL PERFOR- MANCE"
        containerColor={"#F7F7F7"}
        desc="Our software quality assurance professionals test software, preventatively recommending risk mitigation strategies, seeking and reporting defects and collaborating with software developers and project stakeholders."
      />
      <Stack position="relative" mb="50px">
        <Text
          fontFamily={"Trenda-heavy"}
          fontSize={["3em", "6em"]}
          color="#e9e7ec"
          ml="80px"
        >
          WHAT
        </Text>
        <Text
          color="#100120"
          fontFamily={"Trenda-bold"}
          position="absolute"
          bottom="-15px"
          maxWidth="500px"
          marginLeft="50px !important"
          fontSize={"1.5em"}
        >
          WHAT WE DO
        </Text>
      </Stack>
      <ListBoxes lists={lists} />
      <ContactBanner />
    </Box>
  );
}

export default QualityAssurance;
