import { Flex, Img, useMediaQuery } from "@chakra-ui/react";
import Logo from "../../assets/logo.png";
import "./navbar.css";
import { Link } from "react-router-dom";
import WideScreenNav from "./components/WideScreenNav";
import MobileNav from "./components/MobileNav";
import { useEffect, useState } from "react";
export const NAV_LINKS = [
  {
    title: "About",
    link: "/about",
  },
  {
    title: "Team",
    link: "/teams",
  },

  {
    title: "Services",
    link: "/services",
  },
  {
    title: " Products",
    link: "/products",
  },
  {
    title: "Portfolio",
    link: "/portfolio",
  },

  {
    title: "Blog",
    link: "/blog",
  },
  {
    title: "Contact Us",
    link: "/contact",
  },
];

const Navbar = () => {
  const [isLargerThan1280] = useMediaQuery("(min-width: 1024px)");
  const [aboveScrollLimit, setAboveScrollLimit] = useState(false);

  useEffect(() => {
    let myNav = document.getElementById("nav_bar");

    window.onscroll = function () {
      if (window.pageYOffset >= 150) {
        setAboveScrollLimit(true);
        myNav.style.backgroundColor = "#E4F3F6";
        // myli.style.color = "#100120 !important";
      } else {
        setAboveScrollLimit(false);
        myNav.style.backgroundColor = "transparent";
        // myli.style.color = "#fff !important";
      }
    };

    //eslint-disable-next-line
  }, [window.pageYOffset]);

  return (
    <Flex
      bg={isLargerThan1280 ? "transparent" : "#e4f3f6"}
      zIndex="77"
      justifyContent="space-between"
      alignItems="center"
      position="fixed"
      width="100%"
      padding={["1.5rem", "1.5rem 6rem"]}
      className="nav_bar"
      id="nav_bar"
    >
      <Link to="/">
        <Img loading="lazy" w={["120px", "150px"]} src={Logo} />
      </Link>{" "}
      {isLargerThan1280 ? (
        <WideScreenNav aboveScrollLimit={aboveScrollLimit} />
      ) : (
        <MobileNav />
      )}
    </Flex>
  );
};

export default Navbar;
