import { Box, Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import ContactBanner from "../../../landing/components/contactBanner/contactbanner";
import ListBoxes from "../ListBoxes";
import ServiceDetailHeader from "../ServiceDetailHeader";
import TalentOutsourcingOne from "../../../../assets/talent-outsourcing-1.png";
import TextOverlayOnImage from "../TextOverlayOnImage";

function TalentOutsourcing() {
  const lists = [
    {
      title: "Training",
      description: "Provides exhaustive training on various tech stacks",
    },
    {
      title: "Assessment",
      description: "Asses trainee knowledge on tech stack of focus.",
    },
    {
      title: "Request",
      description:
        "Get request from firms asking for highly skilled professionals.",
    },
    {
      title: "Supply",
      description: "Provide top tech talents to requesting firms.",
    },
    {
      title: "Manage",
      description:
        "Manage and optimize the performance of talents provided to firms.",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box data-aos="fade-up" bg="#fefffe">
      <ServiceDetailHeader
        subHeading={"Talent Outsourcing"}
        heading={
          "GET QUALITY TECH PROFESSIONALS FOR YOUR TECH RELATED PROJECTS ANY DAY ANYTIME"
        }
      />
      <TextOverlayOnImage
        img={TalentOutsourcingOne}
        title={"WE HAVE A LARGE POOL OF QUALIFIED TECH PROFESSIONALS"}
        desc="We serve as the bridge between companies and tech professionals providing high quality tech talents for any kind of project implementation. Our Tech professionals are properly trained and have been tested overtime."
        containerColor="#f7f7f7ed"
      />
      <Stack position="relative" mb="50px">
        <Text
          fontFamily={"Trenda-heavy"}
          fontSize={["3em", "6em"]}
          color="#e9e7ec"
          ml="80px"
        >
          WHAT
        </Text>
        <Text
          color="#100120"
          fontFamily={"Trenda-bold"}
          position="absolute"
          bottom="-15px"
          maxWidth="500px"
          marginLeft="50px !important"
          fontSize={"1.5em"}
        >
          WHAT WE DO
        </Text>
      </Stack>

      <ListBoxes lists={lists} />
      <ContactBanner />
    </Box>
  );
}

export default TalentOutsourcing;
