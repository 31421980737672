import { Box, Text } from "@chakra-ui/react";

const Info = () => {
  return (
    <Box padding={["4rem 2rem 8rem 2rem", "4rem 6rem 8rem 6rem"]}>
      <Text color="#100120" fontSize="1.8rem" className="bold">
        Our Zeal to build the better way
      </Text>
      <Text
        mt={4}
        lineHeight="38px"
        color="#707070"
        maxW="700px"
        fontSize="1.2rem"
      >
        Our method arose from the need to create custom solutions for each
        situation. We've made a conscious effort to be agile in our operations
        so that we can create solutions that work.
      </Text>
    </Box>
  );
};

export default Info;
