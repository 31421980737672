import { Box, Button, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Bg from "../../../assets/gradientbg.svg";
import One from "../assets/one.png";
import placeholder from "../assets/one-initial.jpeg";
import "./hero.css";
import IncludeBallAnimation from "../../../components/IncludeBallAnimation";

const Hero = () => {
  const navigate = useNavigate();

  return (
    <Box
      paddingTop="5.5rem"
      pos="relative"
      backgroundImage={Bg}
      backgroundSize="cover"
      height={["100%", "100%", "100%", "100vh"]}
      pb="10px"
      overflow={"hidden"}
    >
      <IncludeBallAnimation />

      <Stack
        alignItems={"center"}
        direction={["column", "column", "column", "row"]}
        height="100%"
      >
        <Box flex="1" width={["100%", "100%", "100%"]} data-aos="fade-right">
          <Image
            src={One}
            width={["100%", "100%", "550px"]}
            style={{ transform: "translate(0rem, 2.2rem)" }}
            placeholder={placeholder}
            alt="landing image"
            loading="lazy"
          />
          <Box position={"absolute"} right="40px" bottom="30px">
            <Box
              // ml="2px"
              display={"inline-block"}
              w="10px"
              h="10px"
              borderRadius="50%"
              bg="#00FE98"
              ml="30px"
            />
          </Box>
        </Box>
        <Box
          flex="1"
          alignSelf="center"
          data-aos="fade-up"
          pb="20px"
          mt={["3px !important", "40px !important"]}
          w={["100%", "initial"]}
          px={["30px", "initial"]}
        >
          <Heading
            transform={[
              "translate(-1.8rem, -.5rem)",
              "translate(-2.8rem, -.5rem)",
            ]}
            lineHeight="1.5rem"
            fontSize={["2.5em", "100px"]}
            color="#fff"
            className="hero-bold-txt"
            mt={["10px", "initial"]}
          >
            INNOVATIVE
          </Heading>
          <Heading
            lineHeight="5rem"
            fontSize={["1.5em", "70px"]}
            color="#290F44"
            className="hero-bold-txt"
          >
            TECHNOLOGY
          </Heading>
          <Heading
            fontSize={["1.5em", "70px"]}
            color="#290F44"
            className="hero-bold-txt"
          >
            SOLUTIONS
            <Box
              // ml="2px"
              display={"inline-block"}
              w="10px"
              h="10px"
              borderRadius="50%"
              bg="#00FE98"
            ></Box>
          </Heading>

          <Text w={["100%", "530px"]} maxW="530px" mt={8}>
            Team of exceptionally talented professionals working together to
            build the technology that solves immediate problems and those of the
            future.
          </Text>

          <Button
            mt={10}
            width={170}
            padding="1.5rem 3rem"
            borderRadius="5px"
            bg="#290F44"
            _hover={{ background: "#290F44" }}
            color="#fff"
            fontSize="14px"
            onClick={() => navigate("/contact")}
          >
            LET'S TALK
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default Hero;
