import { Box, Flex, Heading, HStack, Img, Stack, Text } from "@chakra-ui/react";
import ContactBanner from "../../landing/components/contactBanner/contactbanner";
import dots from "../assets/dots.svg";

export const SingleProductPage = ({
  product_landing_page,
  view,
  product,
  purpose,
  projectClient,
  pr,
  aboutProject,
  aboutImage,
  bannerBg,
  landingBG,
}) => {
  return (
    <>
      <Flex
        bg={bannerBg}
        direction={["column", "column", "column", "row"]}
        paddingTop={["5.5rem"]}
        pl={["2rem", "5rem"]}
        justify="space-between"
        align="center"
        gap="2rem"
        h={["100%", "100%", "100%", "100vh"]}
        pb={["2rem"]}
      >
        <Stack data-aos="fade-left">
          <Text fontSize="1.5rem">{product}</Text>
          <Heading
            fontSize={["2.8rem", "2.8rem", "2.8rem", "3rem"]}
            maxW={["100%", "80%"]}
          >
            {purpose}
          </Heading>
          <HStack pt={["3rem"]} gap={["1rem", "1rem"]}>
            <Stack>
              <Text>Project Duration</Text>
              <Heading fontSize={["1rem"]}>3 months</Heading>
            </Stack>

            <Stack>
              <Text>Project Client</Text>
              <Heading fontSize={["1rem"]}>{projectClient}</Heading>
            </Stack>
          </HStack>
        </Stack>
        <Box pr={pr} display={["none", "block"]} data-aos="fade-right">
          <Img loading="lazy" src={view} alt="view" />
        </Box>
      </Flex>

      <Flex
        pr={["1rem", "1rem", "5rem"]}
        py="5rem"
        gap={[null, null, "3rem"]}
        direction={["column", "column", "column", "row"]}
        justify={["space-between"]}
        align="center"
        data-aos="fade-up"
      >
        <Flex pos="relative" gap="2rem" left={["-1rem", "-2rem", "-5rem"]}>
          <Flex align={"center"} justify={"center"}>
            <Img loading="lazy" src={dots} alt="dots" />
          </Flex>
          <Box>
            <Img loading="lazy" src={aboutImage} alt="dots" />
          </Box>
        </Flex>
        <Stack py="5rem" w={["90%", "90%", "90%", "50%"]} mx="auto">
          <Heading pb="5" fontSize="2.5rem">
            About The Project
          </Heading>
          <Text>{aboutProject}</Text>
        </Stack>
      </Flex>

      <Flex className="howbodi-landing-container" bgColor={landingBG}>
        <Box data-aos="fade-up">
          <Img loading="lazy" src={product_landing_page} alt="howbodi" />
        </Box>
      </Flex>
      <ContactBanner />
    </>
  );
};
