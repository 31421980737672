import { Box, Img } from "@chakra-ui/react";
import zealightWatermark from "../assets/zealight-watermark.svg";

function ZealightWatermark({ ...props }) {
  return (
    <Box
      position={"absolute"}
      right="-200px"
      top="100px"
      data-aos="fade-right"
      {...props}
    >
      <Img loading="lazy" src={zealightWatermark} alt="zealight watermark" />
    </Box>
  );
}

export default ZealightWatermark;
