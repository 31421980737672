import { Box, Flex, HStack, Img, Text } from "@chakra-ui/react";

import Chaticon from "../home-5/chaticon.png";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

const ReviewCards = () => {
  const scrollRight = () => {
    let obj = document.getElementById("review-scroll-1");
    obj.scrollBy(400, 0);
  };
  const scrollLeft = () => {
    let obj = document.getElementById("review-scroll-1");
    obj.scrollBy(-400, 0);
  };
  const borderStyle = {
    border: "1px solid rgb(246, 246, 246)",
    px: "1.5em",
    py: ".9em",
    mb: "10px",
    flex: "1",
    minW: "450px",
    borderRadius: "10px",
    boxShadow: "0px 3px 12px #0000001F",
    bg: "#fff",
    zIndex: "2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  };
  return (
    <Box marginTop="-7rem">
      <Flex
        width="100%"
        gap="2rem"
        justifyContent="space-between"
        overflowX="scroll"
        id="review-scroll-1"
        className="scroll"
        scrollBehavior={"smooth"}
      >
        <Box {...borderStyle}>
          {/* <Text color="#290F44" className="bold">
            The procedure is practical, and the team works well together to
            ensure that the needs are understood.
          </Text> */}
          <Text mt={7}>
            All of our IT needs are managed and handled by Euidem IT Solutions{" "}
            <br />
            and we have no regrets what so ever doing that. I will gladly
            recommend.
          </Text>
          <Flex alignItems="center" justifyContent="space-between" mt={5}>
            <HStack gap="1rem" alignItems="center">
              {/* <Avatar size="lg" src={Avatarx} /> */}
              <Box>
                <Text mb={2} color="#290F44" className="bold" fontSize=".9rem">
                  Ola
                </Text>
                <Text color="#717C91" className="bold" fontSize=".8rem">
                  Manager, Pinnacle Medical Services
                </Text>
              </Box>
            </HStack>
            <Img loading="lazy" width="10%" src={Chaticon} />
          </Flex>
        </Box>
        <Box {...borderStyle}>
          <Text mt={7}>
            Your customer services is exceptional.
            <br />
            It has been very nice working with you..
          </Text>
          <Flex alignItems="center" justifyContent="space-between" mt={5}>
            <HStack gap="1rem" alignItems="center">
              <Box>
                <Text mb={2} color="#290F44" className="bold" fontSize=".9rem">
                  Goshen Province 1, Living Faith Church, Abuja.
                </Text>
                <Text color="#717C91" className="bold" fontSize=".8rem">
                  Church Secretary
                </Text>
              </Box>
            </HStack>
            <Img loading="lazy" width="10%" src={Chaticon} />
          </Flex>
        </Box>
        <Box
          {...borderStyle}
          // height="300px"
        >
          <Text mt={7}>
            Our Associations Management solution was built by Euidem <br /> and
            they did a very fantastic job, very commendable.
          </Text>
          <Flex alignItems="center" justifyContent="space-between" mt={5}>
            <HStack gap="1rem" alignItems="center">
              {/* <Avatar size="lg" src={Avatarx} /> */}
              <Box>
                <Text mb={2} color="#290F44" className="bold" fontSize=".9rem">
                  Mr Dimeji
                </Text>
                <Text color="#717C91" className="bold" fontSize=".8rem">
                  Manager, Embassy Family Ibadan CICS
                </Text>
              </Box>
            </HStack>
            <Img loading="lazy" width="10%" src={Chaticon} />
          </Flex>
        </Box>
        <Box {...borderStyle}>
          {/* <Text color="#290F44" className="bold">
            The procedure is practical, and the team works well together to
            ensure that the needs are understood.
          </Text> */}
          <Text mt={7}>
            My art works that have been featured on top media houses in Nigeria
            (Channels, NTA, Moments with Mo etc) are now accessible to the
            world. <br /> I have had no problems at all dealing with them.
          </Text>
          <Flex alignItems="center" justifyContent="space-between" mt={5}>
            <HStack gap="1rem" alignItems="center">
              {/* <Avatar size="lg" src={Avatarx} /> */}
              <Box>
                <Text mb={2} color="#290F44" className="bold" fontSize=".9rem">
                  2th Pick Arts
                </Text>
                <Text color="#717C91" className="bold" fontSize=".8rem">
                  Art Director
                </Text>
              </Box>
            </HStack>
            <Img loading="lazy" width="10%" src={Chaticon} />
          </Flex>
        </Box>
      </Flex>
      <Flex mb={4} mt={12} justifyContent="center">
        <Box>
          <ArrowBackIcon
            fontSize="1.8rem"
            marginRight={4}
            onClick={scrollLeft}
            cursor="pointer"
          />
          <ArrowForwardIcon
            fontSize="1.8rem"
            onClick={scrollRight}
            cursor="pointer"
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default ReviewCards;
