import { ListItem, UnorderedList } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { NAV_LINKS } from "../navbar";

function WideScreenNav({ aboveScrollLimit }) {
  const location = useLocation();

  return (
    <UnorderedList
      display="flex"
      alignItems="center"
      gap="3.5rem"
      listStyleType="none"
      fontSize={".9em"}
    >
      {NAV_LINKS.map((navLink) => {
        let contactStyle =
          navLink.link === "/contact"
            ? {
                background: "#290F44",
                padding: "10px 25px",
                borderRadius: "5px",
                color: "#fff",
              }
            : {};
        return (
          <Link to={navLink.link}>
            <ListItem
              className="li"
              id="l"
              fontWeight="600"
              color={
                location.pathname === "/teams" && !aboveScrollLimit
                  ? "#fff"
                  : "#100120"
              }
              {...contactStyle}
            >
              {navLink.title}
            </ListItem>
          </Link>
        );
      })}
    </UnorderedList>
  );
}

export default WideScreenNav;
