import React, { useEffect } from "react";
import { SingleProductPage } from "../howbodi/components/SingleProductPage";
import nailbanner from "./assets/nailkeeper.png";
import nail from "./assets/nail.png";
import nail_landing from "./assets/naillanding.png";

const NailKeeper = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SingleProductPage
        product="NailKeeper"
        bannerBg="#FCF8F5D9"
        purpose="The Nail Keeper"
        projectClient="Nail Canada"
        view={nailbanner}
        pr={"6rem"}
        aboutProject="The Nail Keeper is a Nail store management system with complete automation software built for one of Canada’s nail salon brands. The software completely manages all the activities at the salon including booking a service, booking a party, hob rotations for over 100 staff members and a charity ran alongside Starbucks."
        product_landing_page={nail_landing}
        landingBG="#224869ff"
        aboutImage={nail}
      />
    </>
  );
};

export default NailKeeper;
