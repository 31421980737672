import {
  Box,
  Flex,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useEffect } from "react";
import ContactBanner from "../landing/components/contactBanner/contactbanner";

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Flex
        justifyContent={"center"}
        padding="5.5rem 6rem 0 6rem"
        data-aos="fade-up"
      >
        <Box marginTop={"6rem"}>
          <Heading
            marginLeft="100px"
            lineHeight="50px"
            size="lg"
            className="bold"
            fontSize="7rem"
            color="#290F44"
            opacity="0.1"
          >
            FAQs
          </Heading>
          {/* <Flex> */}
          <Heading
            maxW="800px"
            fontWeight="800"
            className="bold"
            fontSize="1.9rem"
            color="#290F44"
          >
            HAVE A QUESTION? LOOK HERE
          </Heading>
          {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
          {/* </Flex> */}
        </Box>
      </Flex>
      <Flex
        justifyContent={"center"}
        padding="3rem 6rem 0 6rem"
        data-aos="fade-up"
      >
        <Box textAlign={"center"} marginTop={"6rem"}>
          <Text mb={2} fontSize=".8" fontWeight={"600"}>
            COMMON QUERIES
          </Text>
          <Text fontSize={"2rem"} className="bold" color="#100120">
            FREQUENTLY ASKED QUESTIONS
          </Text>
        </Box>
      </Flex>

      <Box maxW={"800px"} margin="0 auto 4rem auto" data-aos="fade-up">
        <Accordion mt={12} allowToggle allowMultiple padding="0 .8rem">
          <AccordionItem
            mb={{ base: 2, md: 4 }}
            bg="#FFFFFF"
            border="2px solid #00000012"
          >
            <h2>
              <AccordionButton
                box-shadow="0px 3px 6px #00000012"
                padding="1.2rem"
              >
                <Box
                  fontWeight={"600"}
                  color="#100120"
                  flex="1"
                  textAlign="left"
                >
                  01. Can you build me a website?
                </Box>
                <AccordionIcon color="#100120" />
              </AccordionButton>
            </h2>
            <AccordionPanel color="#848695" maxW="90%" pb={4}>
              Website development is not a service we render as a standalone
              service but if the solution we are providing your business
              includes building a website, then we will build your website along
              with the other products.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            mb={{ base: 2, md: 4 }}
            bg="#FFFFFF"
            border="2px solid #00000012"
          >
            <h2>
              <AccordionButton
                box-shadow="0px 3px 6px #00000012"
                padding="1.2rem"
              >
                <Box
                  fontWeight={"600"}
                  color="#100120"
                  flex="1"
                  textAlign="left"
                >
                  02. Can I schedule a clarity session? I have an idea I will
                  like to build but I will need some help figuring it out
                </Box>
                <AccordionIcon color="#100120" />
              </AccordionButton>
            </h2>
            <AccordionPanel color="#848695" maxW="90%" pb={4}>
              We offer addon HR and Product management services with includes
              attendance tracking and fully product management to ensure your
              staff meets up with their deliverables and KPIs
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            mb={{ base: 2, md: 4 }}
            bg="#FFFFFF"
            border="2px solid #00000012"
          >
            <h2>
              <AccordionButton
                box-shadow="0px 3px 6px #00000012"
                padding="1.2rem"
              >
                <Box
                  fontWeight={"600"}
                  color="#100120"
                  flex="1"
                  textAlign="left"
                >
                  03. Can i pay you to run my business for me?
                </Box>
                <AccordionIcon color="#100120" />
              </AccordionButton>
            </h2>
            <AccordionPanel color="#848695" maxW="90%" pb={4}>
              Website development is not a service we render as a standalone
              service but if the solution we are providing your business
              includes building a website, then we will build your website along
              with the other products.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            mb={{ base: 2, md: 4 }}
            bg="#FFFFFF"
            border="2px solid #00000012"
          >
            <h2>
              <AccordionButton
                box-shadow="0px 3px 6px #00000012"
                padding="1.2rem"
              >
                <Box
                  fontWeight={"600"}
                  color="#100120"
                  flex="1"
                  textAlign="left"
                >
                  04. I need technical partnership for my startup
                </Box>
                <AccordionIcon color="#100120" />
              </AccordionButton>
            </h2>
            <AccordionPanel color="#848695" maxW="90%" pb={4}>
              Website development is not a service we render as a standalone
              service but if the solution we are providing your business
              includes building a website, then we will build your website along
              with the other products.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      <Flex
        justifyContent={"center"}
        padding="1rem 6rem 0 6rem"
        data-aos="fade-up"
      >
        <Box textAlign={"center"}>
          <Text mb={2} fontSize=".8" fontWeight={"600"}>
            OUTSOURCING AND MANAGED HUB SERVICES
          </Text>
          <Text fontSize={"2rem"} className="bold" color="#100120">
            FREQUENTLY ASKED QUESTIONS
          </Text>
        </Box>
      </Flex>

      <Box maxW={"800px"} margin="0 auto 4rem auto" data-aos="fade-up">
        <Accordion mt={12} allowToggle allowMultiple padding="0 .8rem">
          <AccordionItem
            mb={{ base: 2, md: 4 }}
            bg="#FFFFFF"
            border="2px solid #00000012"
          >
            <h2>
              <AccordionButton
                box-shadow="0px 3px 6px #00000012"
                padding="1.2rem"
              >
                <Box
                  fontWeight={"600"}
                  color="#100120"
                  flex="1"
                  textAlign="left"
                >
                  01. I have my staff already i just need a conducive space for
                  them to work
                </Box>
                <AccordionIcon color="#100120" />
              </AccordionButton>
            </h2>
            <AccordionPanel color="#848695" maxW="90%" pb={4}>
              Website development is not a service we render as a standalone
              service but if the solution we are providing your business
              includes building a website, then we will build your website along
              with the other products.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            mb={{ base: 2, md: 4 }}
            bg="#FFFFFF"
            border="2px solid #00000012"
          >
            <h2>
              <AccordionButton
                box-shadow="0px 3px 6px #00000012"
                padding="1.2rem"
              >
                <Box
                  fontWeight={"600"}
                  color="#100120"
                  flex="1"
                  textAlign="left"
                >
                  02. Can I schedule a clarity session? I have an idea I will
                  like to build but I will need some help figuring it out
                </Box>
                <AccordionIcon color="#100120" />
              </AccordionButton>
            </h2>
            <AccordionPanel color="#848695" maxW="90%" pb={4}>
              We offer addon HR and Product management services with includes
              attendance tracking and fully product management to ensure your
              staff meets up with their deliverables and KPIs
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            mb={{ base: 2, md: 4 }}
            bg="#FFFFFF"
            border="2px solid #00000012"
          >
            <h2>
              <AccordionButton
                box-shadow="0px 3px 6px #00000012"
                padding="1.2rem"
              >
                <Box
                  fontWeight={"600"}
                  color="#100120"
                  flex="1"
                  textAlign="left"
                >
                  03. I need technical partnership for my startup
                </Box>
                <AccordionIcon color="#100120" />
              </AccordionButton>
            </h2>
            <AccordionPanel color="#848695" maxW="90%" pb={4}>
              Website development is not a service we render as a standalone
              service but if the solution we are providing your business
              includes building a website, then we will build your website along
              with the other products.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            mb={{ base: 2, md: 4 }}
            bg="#FFFFFF"
            border="2px solid #00000012"
          >
            <h2>
              <AccordionButton
                box-shadow="0px 3px 6px #00000012"
                padding="1.2rem"
              >
                <Box
                  fontWeight={"600"}
                  color="#100120"
                  flex="1"
                  textAlign="left"
                >
                  04. Can i pay you to run my business for me?
                </Box>
                <AccordionIcon color="#100120" />
              </AccordionButton>
            </h2>
            <AccordionPanel color="#848695" maxW="90%" pb={4}>
              Website development is not a service we render as a standalone
              service but if the solution we are providing your business
              includes building a website, then we will build your website along
              with the other products.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
      <ContactBanner />
    </Box>
  );
};

export default Faq;
